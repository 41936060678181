import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { decrementCounter, setRedirected } from "../../redux/slice/timerSlice";

function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="landinpage">
      <Row justify="center">
        <Col xs={22} md={16} lg={10}>
          <h2>Join our community</h2>
          <p>
            Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam
            Nonumy Eirmod Tempor Invidunt Ut Labore Et Dolore Magna Aliquyam
            Erat, Sed Diam Voluptua. At Vero Eos Et Accusam Et Justo Duo Dolores
            Et Ea Rebum.{" "}
          </p>
          <Button className="mainbtn" onClick={() => navigate("/home")}>
            Enter
          </Button>
          <Button className="mainbtn">Exit</Button>
        </Col>
      </Row>
    </div>
  );
}

export default LandingPage;

import React, {useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import { Row, Col, Image, Avatar,message } from 'antd'
import { EVENT, SERVICE, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useNavigate, useParams } from "react-router";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import Packages from "../../components/packges";
import AliceCarousel from "react-alice-carousel";
import FeatureBook1 from "../../assets/eventdetails.png";
import FeatureBook2 from "../../assets/eventdetails.png";
import FeatureBook3 from "../../assets/eventdetails.png";
import dayjs from 'dayjs'
import { ImageUrl } from '../../config/functions';



const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};
const items = [
  <div className="item" data-value="1">
    <Image preview={false} src={FeatureBook1} />
  </div>,
  <div className="item" data-value="2">
    <Image preview={false} src={FeatureBook2} />
  </div>,
  <div className="item" data-value="3">
    <Image preview={false} src={FeatureBook3} />
  </div>,
  <div className="item" data-value="4">
    <Image preview={false} src={FeatureBook1} />
  </div>,
  <div className="item" data-value="5">
    <Image preview={false} src={FeatureBook2} />
  </div>,
];


function Details() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams()
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });


      useEffect(() => {
        getEvent();
      }, []);


      const getEvent = async (pageNumber, pageSize) => {
        setLoading(true);
        try {
          const response = await Get(EVENT.getEventById + id, token);
          setLoading(false);
          console.log("response", response);
          if (response?.status) {
            setEvent(response?.data?.event);
          } else {
            message.error("Something went wrong!");
            console.log("error====>", response);
          }
        } catch (error) {
          console.log(error.message);
          setLoading(false);
        }
      };


  const [UpcomingEvenetsPage, setUpcomingEvenetsPage] = useState([
    {
      key: 1,
      image: ImageUrl("eventdetails.png"),
      title: "Dummy Online Services",
      date: "26",
      month: "june",
      year: "2023",
      locationIcon: ImLocation,
      locationName: "Songrk live, nyc",
      text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    },
  ]);
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={22} lg={22} className="">
                <h2>
                  <Link to="/upcomingevenets"><AiOutlineArrowLeft /></Link> Event Details
                </h2>
              </Col>
              <Col xs={22} md={18} lg={14} className="">
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
              </Col>
            </Row>
            <Row
                    justify="center"
                    className="info-area padding-y-40 margin-y-40 border-radius-15"
                  >
                    <Col xs={22} md={22} lg={22} className="upcomingimage">
                      <div className="datetag">
                        <h6>{dayjs(event.date).format("DD")}</h6>
                        <span>{dayjs(event.date).format("MMM")}</span>
                        <span>{dayjs(event.date).format("YYYY")}</span>
                      </div>
                      <Image height={400} style={{objectFit:'cover',borderRadius:"20px"}} preview={false} src={UPLOADS_URL + "/" + event.image} />
                    
                    </Col>
                    <Col
                      xs={22}
                      md={22}
                      lg={22}
                      className="padding-y-40  border-radius-15"
                    >
                      <h3 className="margin-y-10">{event?.title}</h3>
                      <Row>
                        <Col>
                          <span className="margin-0 goldencolor">
                            <ImLocation /> {event?.location}
                          </span>
                        </Col>
                      </Row>
                      <p className="margin-y-20">{event?.description}</p>
                    </Col>
                  </Row>
          </Col>
        </Row>

        <div className="greybg">
          <Packages />
        </div>
      </div>
    </>
  );
}

export default Details;

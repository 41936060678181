import { useEffect, useState } from "react";
import { Col, Row, Skeleton, Table, Image, Button, Modal } from "antd";
import OrderSummary from "../../components/orderSummary";
import image1 from "../../assets/product4.png";
import QuantitySelector from "../../components/quantity-selector";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UPLOADS_URL } from "../../config/constants/api";
import { updateCartItemQuantity } from "../../redux/slice/cartSlice";

function MyCart() {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cartDetails = useSelector((state) => state.cart);
  console.log("🚀 ~ MyCart ~ cartDetails:", cartDetails);
  const [orders, setOrders] = useState([]);

  // const [orders, setOrders] = useState([
  //   {
  //     _id: 1,
  //     image:image1,
  //     product: "Abc Product",
  //     quantity:10,
  //     unitPrice: "180",
  //     size:"Small",
  //     color:"Silver",
  //     totalPrice:100

  //   },
  //   {
  //       _id: 2,
  //       image:image1,
  //       product: "Abc Product",
  //       quantity: 10,
  //       unitPrice: "180",
  //       size:"Small",
  //       color:"Silver",
  //       totalPrice:100

  //     },
  // ]);

  useEffect(() => {
    getOrders();
  }, []);
  const handleSelectedQuantityChange = (quantity, index) => {
    // let _orders = [...orders];
    // console.log(_orders[index]);

    // console.log(index);
    // _orders[index].quantity = value;
    // setOrders(_orders);
    // dispatch(
    //   updateCartItemQuantity({
    //     quantity: _orders[index].quantity,
    //     product: _orders[index].product,
    //   })
    // );
    dispatch(
      updateCartItemQuantity({
        quantity: quantity,
        name: cartDetails.cartItems[index].product.title,
      })
    );
  };
  const getOrders = async (pageNumber, pageSize, search, reset = false) => {
    //   setLoading(true);
    try {
      // const response = await Get(ORDER.getOrderLogs, token, {
      //   page: pageNumber
      //     ? pageNumber.toString()
      //     : paginationConfig.pageNumber.toString(),
      //   limit: pageSize
      //     ? pageSize.toString()
      //     : paginationConfig.limit.toString(),
      //   status: reset ? "" : filter.status || null,
      //   type: reset ? "" : filter.type || null,
      //   keyword: search ? search : null,
      //   from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
      //   to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
      // });
      // setLoading(false);
      // console.log("response", response);
      // if (response?.status) {
      //   setOrders(response?.data?.docs);
      //   setPaginationConfig({
      //     pageNumber: response?.data?.page,
      //     limit: response?.data?.limit,
      //     totalDocs: response?.data?.totalDocs,
      //     totalPages: response?.data?.totalPages,
      //   });
      // } else {
      //   message.error(response.message);
      //   console.log("error====>", response);
      // }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      width: 100,
      render: (item, value) => (
        <Image
          preview={false}
          src={
            value.product?.gallery &&
            UPLOADS_URL + "/" + value.product?.gallery[0]
          }
          width={"48px"}
          height={"48px"}
          style={{ objectFit: "contain" }}
        />
      ),
    },
    {
      title: "PRODUCT",
      dataIndex: "product",
      key: "product",
      render: (item, value) => <p>{item.title}</p>,
    },
    {
      title: "QUANTITY",
      dataIndex: "quantity",
      key: "quantity",
      render: (item, value, index) => (
        <QuantitySelector
          value={item}
          onChange={(e) => handleSelectedQuantityChange(e, index)}
        />
      ),
    },

    {
      title: "UNIT PRICE",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (item, value) => <span>${value.product.price}</span>,
    },
    // {
    //   title: "Size",
    //   dataIndex: "size",
    //   key: "size",
    // },
    // {
    //   title: "Color",
    //   dataIndex: "color",
    //   key: "color",
    // },
    {
      title: "Total",
      dataIndex: "totalPrice",
      key: "totalPrice",
      render: (item, value) => (
        <span>${value.quantity * value.product.price}</span>
      ),
    },
  ];

  const handleCheckout = () => {
    // orders.map((order) => {
    // dispatch(
    //   updateCartItemQuantity({
    //     product: order.product,
    //     quantity: order.quantity,
    //   })
    // );
    // });

    navigate("/check-out");
  };
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={22} xl={22}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>My Cart</h2>
            </Col>

            <Col xs={22}>
              <Row gutter={30}>
                <Col xs={24} md={15} lg={17}>
                  <Row style={{ padding: 20, overflow: "auto" }}>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Skeleton active />
                        <br />
                      </div>
                    ) : (
                      <Table
                        className="styledTable2"
                        dataSource={cartDetails?.cartItems}
                        columns={columns}
                        pagination={false}
                      />
                    )}
                  </Row>
                </Col>
                <Col xs={24} md={9} lg={7}>
                  <OrderSummary subTotal={cartDetails.subTotal} tax={10} />
                </Col>
              </Row>
              <Row justify="left">
                <Button className="mainbtn" onClick={handleCheckout}>
                  Proceed to Checkout
                </Button>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default MyCart;

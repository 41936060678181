import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Image,
  Card,
  Rate,
  Button,
  Radio,
  Typography,
  Progress,
  Form,
  Input,
  message,
} from "antd";
import ImageGrid from "../../components/imagegrid";
import QuantitySelector from "../../components/quantity-selector";
import ReviewCaurosel from "../../components/reviewCaurosel";
import StoreProduct from "../../components/storeproduct";
import { ImageUrl } from "../../config/functions";
import { useNavigate, useParams } from "react-router-dom";
import { PRODUCT, REVIEW } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { Post } from "../../config/api/post";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/slice/cartSlice";

function ProductDetails() {
  const [colors, setColors] = useState(["#FF0000", "#F6D21C", "#12E119"]);
  const [size, setSize] = useState(["S", "M", "L", "XL"]);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const dispatch = useDispatch();
  const handleSelectedQuantityChange = (newQuantity) => {
    setSelectedQuantity(newQuantity);
  };
  const [ratings, setRatings] = useState({
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 100,
  });
  const [averageRating, setAverageRating] = useState(0);
  const [rating, setRating] = useState(0);
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const token = useSelector((state) => state.user.userToken);
  const [comment, setComment] = useState("");

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    getProduct();
    getAllReviewsByProductId();
    getProductRating();
  }, []);

  const handleAddToCart = () => {
    dispatch(
      addToCart({
        product,
        quantity: selectedQuantity,
      })
    );
    message.success("Product added to cart!");
  };

  const onFinish = async () => {
    try {
      const response = await Post(
        REVIEW.addReview + id,
        {
          comment,
          rating,
        },
        token
      );

      if (response.status) {
        message.success("Comment posted successfully!");
        getAllReviewsByProductId();
        getProductRating();
      } else {
        message.error("Failed to post comment!");
      }
    } catch (error) {
      console.error(error);
      message.error("An error occurred while posting the comment.");
    } finally {
      setComment("");
      setRating(0);
    }
  };
  const getAllReviewsByProductId = async () => {
    try {
      const response = await Get(REVIEW.getAllReviewsByProductId + id, token);
      console.log("response", response);
      if (response?.status) {
        setReviews(response?.data?.reviews);
        setRatings(response?.data?.ratings);
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {}
  };
  const getProductRating = async () => {
    try {
      const response = await Get(REVIEW.getProductRating + id, token);
      console.log("response", response);
      if (response?.status) {
        setAverageRating(response?.data?.averageRating);
        setTotalReviews(response?.data?.totalReviews);
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProduct = async () => {
    setLoading(true);
    try {
      const response = await Get(PRODUCT.getProductById + id, token);
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setProduct(response?.data?.product);
        console.log(response?.data?.product);
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      {product ? (
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <div className="greybg padding-y-40 padding-x-30 margin-y-30">
              <Row gutter={20}>
                <Col xs={22} md={22} lg={11}>
                  <ImageGrid smallImages={product?.gallery} />
                </Col>
                <Col xs={22} md={22} lg={11}>
                  <h3 className="margin-y-10 font-bold text-2xl">
                    {product.title}
                  </h3>
                  <div>
                    <Rate defaultValue={3} />
                    <span className="ant-rate-text font-medium">
                      4.5 Ratings & 2 Reviews
                    </span>
                  </div>
                  <span className="productprice">${product.price}</span>
                  <div className="colors">
                    <span>Color</span>
                    <Radio.Group size="large">
                      {colors.length > 0 &&
                        colors.map((item) => {
                          return (
                            <Radio.Button
                              value={item}
                              style={{ backgroundColor: item }}
                              className="colorRadio"
                            ></Radio.Button>
                          );
                        })}
                    </Radio.Group>
                  </div>
                  <div className="quantity">
                    <span>Quantity</span>

                    <QuantitySelector
                      value={selectedQuantity}
                      onChange={handleSelectedQuantityChange}
                    />
                  </div>
                  <div className="size">
                    <span>Select Size</span>
                    <Radio.Group size="large">
                      {size.length > 0 &&
                        size.map((item) => {
                          return (
                            <Radio.Button value={item} className="sizeRadio">
                              {item.toUpperCase()}
                            </Radio.Button>
                          );
                        })}
                    </Radio.Group>
                  </div>
                  <Button className="mainbtn" onClick={handleAddToCart}>
                    Add To Cart
                  </Button>
                </Col>
              </Row>
            </div>

            <Row>
              <Col xs={24} className="productreview">
                <h3>product specification</h3>
                <p>{product.description}</p>
                {/* <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s., when an unknown printer took a galley of
                  type and scrambled it to make a type specimen book. It has
                  survived not only five centuries, but also the leap into
                  electronic typesetting, remaining essentially unchanged.
                </p> */}
              </Col>

              <Col xs={24}>
                <div className="reviewarea">
                  <Row gutter={20} justify={"center"}>
                    <Col xs={22} md={16}>
                      <Row>
                        <Col xs={22}>
                          <h3>REVIEWS</h3>
                        </Col>
                        <Col xs={22} md={22} lg={12}>
                          <Row>
                            <Col xs={22}>
                              <Typography.Title
                                className="fontFamily1"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#BF9343",
                                  textAlign: "left",
                                  display: "block",
                                  marginTop: 5,
                                  marginBottom: 0,
                                }}
                              >
                                {averageRating === 0
                                  ? "Not rated yet"
                                  : averageRating}
                              </Typography.Title>
                              <Rate
                                disabled
                                allowHalf
                                value={averageRating}
                                style={{
                                  color: "#FABF35",
                                  marginTop: 0,
                                  fontSize: 18,
                                }}
                              />
                            </Col>

                            <Typography.Title
                              className="fontFamily1"
                              style={{
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#B2B2B2",
                                textAlign: "left",
                                marginTop: 10,
                                marginBottom: 20,
                              }}
                            >
                              {averageRating} Ratings & {totalReviews} Reviews
                            </Typography.Title>
                          </Row>

                          {/* <Button className="mainbtn">Write Your Review</Button> */}
                        </Col>
                        <Col xs={22} md={22} lg={12}>
                          <Row
                            gutter={[20, 0]}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <Col>
                              <Typography.Text
                                className="fontFamily1"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                5 Star
                              </Typography.Text>
                            </Col>

                            <Col xs={18}>
                              <Progress
                                percent={ratings[5]}
                                strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                                style={{ width: "100%", color: "#FABF35" }}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={[20, 0]}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <Col>
                              <Typography.Text
                                className="fontFamily1"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                4 Star
                              </Typography.Text>
                            </Col>

                            <Col xs={18}>
                              <Progress
                                percent={ratings[4]}
                                strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                                style={{ width: "100%", color: "#FABF35" }}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={[20, 0]}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <Col>
                              <Typography.Text
                                className="fontFamily1"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                3 Star
                              </Typography.Text>
                            </Col>

                            <Col xs={18}>
                              <Progress
                                percent={ratings[3]}
                                strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                                style={{ width: "100%", color: "#FABF35" }}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={[20, 0]}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <Col>
                              <Typography.Text
                                className="fontFamily1"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                2 Star
                              </Typography.Text>
                            </Col>

                            <Col xs={18}>
                              <Progress
                                percent={ratings[2]}
                                strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                                style={{ width: "100%", color: "#FABF35" }}
                              />
                            </Col>
                          </Row>
                          <Row
                            gutter={[20, 0]}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              margin: 0,
                            }}
                          >
                            <Col>
                              <Typography.Text
                                className="fontFamily1"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textAlign: "left",
                                  fontWeight: "bold",
                                }}
                              >
                                1 Star
                              </Typography.Text>
                            </Col>

                            <Col xs={18}>
                              <Progress
                                percent={ratings[1]}
                                strokeColor={{ from: "#FABF35", to: "#FABF35" }}
                                style={{ width: "100%", color: "#FABF35" }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={22}>
                      <ReviewCaurosel reviews={reviews} />
                    </Col>

                    <Col xs={22}>
                      <h5>Post a Comment</h5>
                      <Form onFinish={onFinish}>
                        <Form.Item
                          name="rating"
                          rules={[
                            {
                              required: true,
                              message: "Please provide a rating!",
                            },
                          ]}
                        >
                          <Rate
                            value={rating}
                            onChange={(value) => setRating(value)}
                          />
                        </Form.Item>
                        <Form.Item
                          name="comment"
                          rules={[
                            {
                              required: true,
                              message: "Please provide a comment!",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Your Message Here!"
                            value={comment}
                            onChange={handleCommentChange}
                          />
                        </Form.Item>
                        <Col xs={22} style={{ textAlign: "center" }}>
                          <Button
                            className="mainbtn padding-x-40"
                            htmlType="submit"
                          >
                            Post
                          </Button>
                        </Col>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row justify={"center"}>
              <Col xs={22} md={18} lg={16}>
                <h2>Recommended Products</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
              </Col>
            </Row>
            {/* <Row justify="center" gutter={20}>
              <StoreProduct StoreProductCards={StoreProductCards} />
            </Row> */}
          </Col>
        </Row>
      ) : (
        navigate(-1)
      )}
    </>
  );
}

export default ProductDetails;

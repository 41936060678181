import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import LandingPage from "../../views/firstPage";
import Home from "../../views/home";
import About from "../../views/about";
import OnlineServices from "../../views/onlineservices";
import Articles from "../../views/articles";
import NonMasonic from "../../views/nonmasonic";
import TermsCondition from "../../views/term-conditions";
import PremiumContent from "../../views/premiumcontent";
import ClientLayout from "../../components/layout";
import LibraryAccess from "../../views/libraryaccess";
import LibararyContent from "../../views/libararycontent";
import Glory from "../../views/glory";
import FirstDegree from "../../views/glory/first-degree";
import DegreeDetails from "../../views/glory/degree-details";
import LibraryContentFirstRankTopic from "../../views/glory/library-content-first-rank-topic";
import LibraryContentDetails from "../../views/glory/library-content-details";
import FeaturedbooksPage from "../../views/featuredbooks";
import MasonicBooks from "../../views/masonicbooks";
import UpcomingEvenetsPage from "../../views/upcomingeventspage";
import Details from "../../views/upcomingeventspage/details";
import LiveStreaming from "../../components/livestreaming";
import AdvertiseBusiness from "../../views/advertisebusiness";
import Login from "../../views/login";
import SignUp from "../../views/signup";
import LoginSubscription from "../../views/loginsubscription";
import CardDetails from "../../views/carddetails";
import ForgetPassword from "../../views/forgetpassword";
import ForgetPasswordCode from "../../views/fogetpasswordcode";
import PasswordRecovery from "../../views/passwordrecovery";
import CreateAdd from "../../views/createadd";
import SupplyStore from "../../views/supplustore";
import ProductDetails from "../../views/supplustoreproductdetails";
import MyCart from "../../views/myCart";
import CheckOut from "../../views/checkOut";
import ContactUs from "../../views/contactUs";

import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Managesubscription from "../../views/profile/managesubscription";
import OrderHistory from "../../views/profile/orderhistory";
import OrderDetail from "../../views/profile/orderDetail";
import SignUpForm1 from "../../views/Forms/SignUpForm1/SignUpForm1";
import { useDispatch, useSelector } from "react-redux";
import { decrementCounter, setRedirected } from "../../redux/slice/timerSlice";
import MasonicArticles from "../../views/masonicArticles";
import NonMasonicArticles from "../../views/Non-masonicArticle";
import ReligiousArticles from "../../views/ReligiousArticles";

function MyRouter() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" index element={<LandingPage />} />
        <Route
          path="/home"
          index
          element={
            <ClientLayout>
              <Home />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          element={
            <ClientLayout header={false} footer={false}>
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/signupform/:step"
          element={
            <ClientLayout header={false} footer={false}>
              <SignUpForm1 />
            </ClientLayout>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <ClientLayout header={false} footer={false}>
              <ForgetPassword />
            </ClientLayout>
          }
        />
        <Route
          path="/forgetpasswordcode"
          element={
            <ClientLayout header={false} footer={false}>
              <ForgetPasswordCode />
            </ClientLayout>
          }
        />
        <Route
          path="/passwordrecovery"
          element={
            <ClientLayout header={false} footer={false}>
              <PasswordRecovery />
            </ClientLayout>
          }
        />
        <Route
          path="/signup"
          element={
            <ClientLayout header={false} footer={false}>
              <SignUp />
            </ClientLayout>
          }
        />
        <Route
          path="/loginsubscription"
          element={
            <ClientLayout header={false} footer={false}>
              <LoginSubscription />
            </ClientLayout>
          }
        />
        <Route
          path="/carddetails"
          element={
            <ClientLayout header={false} footer={false}>
              <CardDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/about"
          element={
            <ClientLayout header={true}>
              <About />
            </ClientLayout>
          }
        />
        <Route
          path="/livestreaming"
          element={
            <ClientLayout header={true}>
              <LiveStreaming />
            </ClientLayout>
          }
        />
        <Route
          path="/onlineservices"
          element={
            <ClientLayout header={true}>
              <OnlineServices />
            </ClientLayout>
          }
        />
        {/* <Route
          path="/articles"
          element={
            <ClientLayout header={true}>
              <Articles />
            </ClientLayout>
          }
        /> */}
        <Route
          path="/masonicCommunity"
          element={
            <ClientLayout header={true}>
              <Articles />
            </ClientLayout>
          }
        />
        <Route
          path="/nonmasoniccomunity"
          element={
            <ClientLayout header={true}>
              <NonMasonic />
            </ClientLayout>
          }
        />
        <Route
          path="/masonicArticles"
          element={
            <ClientLayout header={true}>
              <MasonicArticles />
            </ClientLayout>
          }
        />
        <Route
          path="/nonMasonicArticles"
          element={
            <ClientLayout header={true}>
              <NonMasonicArticles />
            </ClientLayout>
          }
        />
        <Route
          path="/religiousArticles"
          element={
            <ClientLayout header={true}>
              <ReligiousArticles />
            </ClientLayout>
          }
        />
        <Route
          path="/termsandconditions"
          element={
            <ClientLayout header={true}>
              <TermsCondition />
            </ClientLayout>
          }
        />
        <Route
          path="/premiumcontent"
          element={
            <ClientLayout header={true}>
              <PremiumContent />
            </ClientLayout>
          }
        />
        <Route
          path="/libraryaccess"
          element={
            <ClientLayout header={true}>
              <LibraryAccess />
            </ClientLayout>
          }
        />
        <Route
          path="/librarycontent"
          element={
            <ClientLayout header={true}>
              <LibararyContent />
            </ClientLayout>
          }
        />
        <Route
          path="/glory/:id"
          element={
            <ClientLayout header={true}>
              <Glory />
            </ClientLayout>
          }
        />
        <Route
          path="/degree/:id"
          element={
            <ClientLayout header={true}>
              <FirstDegree />
            </ClientLayout>
          }
        />
        <Route
          path="/degree-details/:id"
          element={
            <ClientLayout header={true}>
              <DegreeDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/library-content-first-rank-topic/:id"
          element={
            <ClientLayout header={true}>
              <LibraryContentFirstRankTopic />
            </ClientLayout>
          }
        />
        <Route
          path="/library-content-details/:topicId"
          element={
            <ClientLayout header={true}>
              <LibraryContentDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/featuredbooks"
          element={
            <ClientLayout header={true}>
              <FeaturedbooksPage />
            </ClientLayout>
          }
        />
        <Route
          path="/masonicbooks"
          element={
            <ClientLayout header={true}>
              <MasonicBooks />
            </ClientLayout>
          }
        />
        <Route
          path="/upcomingevenets"
          element={
            <ClientLayout header={true}>
              <UpcomingEvenetsPage />
            </ClientLayout>
          }
        />
        <Route
          path="/upcomingeventdetails/:id"
          element={
            <ClientLayout header={true}>
              <Details />
            </ClientLayout>
          }
        />
        <Route
          path="/advertisebusiness"
          element={
            <ClientLayout header={true}>
              <AdvertiseBusiness />
            </ClientLayout>
          }
        />
        <Route
          path="/createadd"
          element={
            <ClientLayout header={true}>
              <CreateAdd />
            </ClientLayout>
          }
        />
        <Route
          path="/supply-store"
          element={
            <ClientLayout header={true}>
              <SupplyStore />
            </ClientLayout>
          }
        />
        <Route
          path="/product-details/:id"
          element={
            <ClientLayout header={true}>
              <ProductDetails />
            </ClientLayout>
          }
        />
        <Route
          path="/my-cart"
          element={
            <ClientLayout header={true}>
              <MyCart />
            </ClientLayout>
          }
        />
        <Route
          path="/check-out"
          element={
            <ClientLayout header={true}>
              <CheckOut />
            </ClientLayout>
          }
        />
        <Route
          path="/contact-us"
          element={
            <ClientLayout header={true}>
              <ContactUs />
            </ClientLayout>
          }
        />

        <Route
          path="/profile"
          element={
            <ClientLayout header={true}>
              <Profile />
            </ClientLayout>
          }
        >
          <Route index element={<EditProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="manage-subscription" element={<Managesubscription />} />
          <Route path="order-history" element={<OrderHistory />} />
          <Route path="order-detail/:id" element={<OrderDetail />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default MyRouter;

import { createSlice } from "@reduxjs/toolkit";

const timerSlice = createSlice({
  name: "timer",
  initialState: {
    counter: 60,
    redirected: true,
  },
  reducers: {
    decrementCounter(state) {
      state.counter -= 1;
    },
    setRedirected(state) {
      state.redirected = true;
    },
    resetTimer(state) {
      state.counter = 60;
      state.redirected = false;
    },
  },
});

export const { decrementCounter, setRedirected, resetTimer } =
  timerSlice.actions;
export default timerSlice.reducer;

import React from 'react';
import Header from './header';
import Footer from './footer';

function ClientLayout({header,children,footer=true}) {
  return (
    
    // <div style={{height:"100vh",overflow:'auto'}}>
    <div style={{height:"100vh",overflowX:'hidden'}}>
    <Header header = {header} />
    {children}
   {footer && <Footer/>}
    </div>
  )
}

export default ClientLayout
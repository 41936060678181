import React, { useState } from "react";
import { Row, Col, List, Typography, Button } from "antd";
import BannerIcons from "../../components/banner-icons/index-vs.js";
import UpcomingEvenets from "../../components/upcoming";
import Packages from "../../components/packges";
import { ImageUrl } from "../../config/functions";
function PremiumContent() {
  const [bannerCards, setBannerCards] = useState([
    {
      key: 1,
      image: ImageUrl("banner-icon-7.png"),
      title: "Religious Articles",
      link: "/articles",
    },
    {
      key: 2,
      image: ImageUrl("banner-icon-4.png"),
      title: "Library Access",
    },
    {
      key: 3,
      image: ImageUrl("banner-icon-8.png"),
      title: "Masonic Articles",
      link: "/articles",
    },
    {
      key: 4,
      image: ImageUrl("banner-icon-6.png"),
      title: "Advertise Business",
      link: "/advertisebusiness",
    },
  ]);

  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>PREMIUM CONTENT</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40">
            <Col xs={22} md={22} lg={22}>
              <BannerIcons
                bannerCards={bannerCards}
                bannerIconClass="bannercards2"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <UpcomingEvenets />

      <Packages />
    </>
  );
}

export default PremiumContent;

import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Get } from "../../config/api";
import { RANK } from "../../config/constants/api";

function LibraryContentFirstRankTopic() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.user.userToken);
  const [topicList, setTopicList] = useState([]);

  useEffect(() => {
    if (id) getTopicList();
  }, []);

  const getTopicList = async () => {
    setLoading(true);
    try {
      const response = await Get(RANK.getTopicList + id, token);
      if (response?.status) {
        setTopicList(response?.data?.topics);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={18} className="text-center">
              <h4>Welcome to Topic Menu for Masonic Ritual of Apprentice</h4>
              <p>
                Please click on, the topic of your choice from 1 to{" "}
                {topicList?.length}
              </p>
            </Col>
            <Col xs={22} md={20} xl={18}>
              {topicList.map((topic) => (
                <div
                  className="topics mousepointer"
                  onClick={() =>
                    navigate("/library-content-details/" + topic._id, {
                      state: { rankId: id },
                    })
                  }
                >
                  <p>{topic.heading}</p>
                  <span>{topic.topicId}</span>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LibraryContentFirstRankTopic;

// import router from "next/router";
import { Row, Space, Skeleton } from "antd";

function PostLoading() {
  return (
    <>
      <div
        className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
        style={{ padding: "40px" }}
      >
        <Row>
          <Space>
            <Skeleton.Avatar active />
            <Skeleton.Button active size="small" style={{ width: "200px" }} />
          </Space>
        </Row>
        <br />
        <Row>
          <Skeleton active size="large" block paragraph={{ rows: 8 }} />
        </Row>

        <br />
      </div>
    </>
  );
}

export default PostLoading;

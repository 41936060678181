import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Checkbox,
  Card,
  Switch,
  Image,
  Divider,
} from "antd";
import { useNavigate, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../../config/api/post";
import { AUTH, SIGNUPFORM } from "../../../config/constants/api";
import { addUser } from "../../../redux/slice/authSlice";
import { FiMail, FiLock } from "react-icons/fi";
import swal from "sweetalert";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";
import { Get } from "../../../config/api/get";

// import router from "next/router";

function SignUpForm1() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { step } = useParams();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);
  const [formQuestion, setformQuestion] = useState();
  const [totalSteps, setSteps] = useState(0);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await Get(
        `${SIGNUPFORM.getSingleQuestion}${step}`,
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NjBjMDc0NmY3Mzc2ZDlmYzVlNTJjY2EiLCJlbWFpbCI6ImF6aXpAZ21haWwuY29tIiwiaXNBZG1pbiI6ZmFsc2UsImlhdCI6MTcxMjE2MDA4NSwiZXhwIjoxNzEyNzY0ODg1fQ.ku9NZgd8rQdCU_KpBq6pduj4BVIFSicvvHCJj2DERoE"
      );
      if (response?.status) {
        setformQuestion(response?.data);
        setSteps(response?.data.step);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    setLoading(true);

    let data = {
      answer: values.answer,
    };
    Post(AUTH.signin, data)
      .then((response) => {
        console.log(response, "#####");
        setLoading(false);
        if (response?.data?.status) {
          if (step === 30) {
            navigate("/dashboard");
            dispatch(
              addUser({
                user: response.data.data.user,
                token: response.data.data.token,
              })
            );
            swal("Success", response.data.message, "success");
          } else {
            navigate(`/signupform/${step + 1}`, { replace: true });
          }
        } else {
          console.log("response", response);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleExit = () => {
    navigate("/");
  };

  return (
    <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh" }}>
        <Col xs={24} md={14} lg={10} xl={8}>
          <Card style={{ borderRadius: "30px" }}>
            <div className="authFormBox">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={22} className="formWrap">
                  <Row justify="start">
                    <div className="inline" onClick={handleExit}>
                      <RxCross2
                        size={34}
                        className="text-black mb-2 cursor-pointer"
                      />
                    </div>
                  </Row>
                  <Typography.Title
                    className="fontFamily1"
                    style={{
                      fontSize: "28px",
                      fontWeight: 800,
                      marginBottom: "5px",
                      color: "black",
                      textTransform: "uppercase",
                    }}
                  >
                    Step {step} of {formQuestion?.totalQuestions}
                  </Typography.Title>
                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      fontWeight: 300,
                      color: "black",
                    }}
                  >
                    Complete These Steps To Log In To The Platform
                  </Typography.Text>
                  <br />
                  <br />
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 20,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label={formQuestion?.question.question}
                      name="question"
                      rules={[
                        {
                          required: true,
                          message: "Please input your answer!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Your Answer"
                        className="AuthFormInput fontFamily1"
                      />
                    </Form.Item>

                    <Row justify="center">
                      <Col md={12}>
                        <Button
                          type="primary"
                          className="loginButton mainbtn"
                          onClick={() => navigate(-1)}
                        >
                          {loading ? "Loading..." : "Back"}
                        </Button>
                      </Col>

                      <Col md={12}>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="loginButton mainbtn"
                          >
                            {loading ? "Loading..." : "Proceed to Next Step"}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>

                  {/* <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    <>
                      Don't have an account?{" "}
                      <span
                        onClick={() => navigate("/signup")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#AE802C",
                        }}
                      >
                        Proceed to Next Step
                      </span>
                    </>
                  </Typography.Text> */}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default SignUpForm1;


import React, {useState,useEffect} from 'react'
import { Row, Col, Image, Avatar,message } from 'antd'
import { SERVICE, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { ImageUrl } from '../../config/functions';



function OnlineServices() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  
  const handlePageChange = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: Number(e.selected) + 1,
    });

    getServices(Number(e.selected) + 1);
  };

      useEffect(() => {
        getServices();
      }, []);


      const getServices = async (pageNumber, pageSize) => {
        setLoading(true);
        try {
          const response = await Get(SERVICE.getAllServices, token, {
            page: pageNumber
              ? pageNumber.toString()
              : paginationConfig.pageNumber.toString(),
            limit: pageSize
              ? pageSize.toString()
              : paginationConfig.limit.toString(),
          });
          setLoading(false);
          console.log("response", response);
          if (response?.status) {
            setServices(response?.data?.docs);
            setPaginationConfig({
              pageNumber: response?.data?.page,
              limit: response?.data?.limit,
              totalDocs: response?.data?.totalDocs,
              totalPages: response?.data?.totalPages,
            });
          } else {
            message.error("Something went wrong!");
            console.log("error====>", response);
          }
        } catch (error) {
          console.log(error.message);
          setLoading(false);
        }
      };



  return (
    <>
      <div>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Online Services</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            {services.length > 0 &&
              services.map((item, index) => {
                return( <Row justify="center" gutter={30} className='info-area padding-y-40 greybg margin-y-40 border-radius-15' key={index}>
                <Col xs={22} md={22} lg={11} >
                <Image preview={false} style={{width:"100%"}} src={UPLOADS_URL + "/" + item.image} />
                    
                    
                    </Col>
                <Col xs={22} md={22} lg={11}>
                    <h3>{item.title}</h3>
                    <Row gutter={20} className='margin-y-15'>
                        <Col>
                        <Avatar size={48} icon={<Image preview={false} src={item?.author?.image ? UPLOADS_URL + "/" + item.author.image : ImageUrl("avatar.png")} />} />
                        </Col>
                        <Col>
                            <h5 className='margin-0'>{item?.author?.fullName}</h5>
                            <p>{moment(item.createdAt).fromNow()} &nbsp; | &nbsp; {moment(item.createdAt).format('LT')}</p>
                        </Col>
                    </Row>
                    <p>{item.description}</p>
                    <h5>{"Contact Details"}</h5>
                    <p>{item.email}</p>
                    <p>{item.phone}</p>
                </Col>
            </Row>);
               
              })}

{services.length > 0 &&   <ReactPaginate
              breakLabel="..."
              nextLabel={<FaArrowRight style={{ color: "grey" }} />}
              pageRangeDisplayed={window.innerWidth > 500 ? 4 : 1}
              marginPagesDisplayed={window.innerWidth > 500 ? 4 : 1} //handle Pa
              onPageChange={handlePageChange}
              pageCount={paginationConfig?.totalPages}
              forcePage={paginationConfig?.pageNumber - 1}
              previousLabel={<FaArrowLeft style={{ color: "grey" }} />}
              renderOnZeroPageCount={null}
              pageClassName="page-item" //m
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="paginationContainer"
              activeClassName="active"
            />}
            
        </Col>
        </Row>

        

      </div>
    </>
  )
}

export default OnlineServices
import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import StoreProduct from "../../components/storeproduct";
import { ImageUrl } from "../../config/functions";
import { useNavigate } from "react-router-dom";
import { Get } from "../../config/api/get";
import { PRODUCT } from "../../config/constants/api";
import { useSelector } from "react-redux";

function SupplyStore() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    status: null,
    keyword: "",
    from: null,
    to: null,
  });

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;

  useEffect(() => {
    getProducts();
  }, []);

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    getProducts(pageNumber);
  };

  const handleSearch = (value) => {
    setFilter({
      ...filter,
      keyword: value,
    });
  };

  const handleStatusChange = (value) => {
    setFilter({
      ...filter,
      status: value,
    });
  };

  const resetFilter = () => {
    setFilter({
      status: null,
      keyword: "",
      from: null,
      to: null,
    });
    getProducts(paginationConfig.pageNumber, paginationConfig.limit, "", true);
  };

  // const handleOpenChange = (newOpen) => {
  //   setOpen(newOpen);
  // };

  const handleFrom = (date) => {
    setFilter({
      ...filter,
      from: date,
    });
  };

  const handleTo = (date) => {
    setFilter({
      ...filter,
      to: date,
    });
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    getProducts(1, pageSize);
  };

  const getProducts = async (pageNumber, pageSize, search, reset = false) => {
    setLoading(true);
    try {
      const response = await Get(PRODUCT.getAllProducts, token, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
        status: reset ? "" : filter.status || null,
        keyword: search ? search : null,
        from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
        to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setProducts(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  // const [StoreProductCards, setStoreProductrCards] = useState([
  //   {
  //     key: 1,
  //     image: ImageUrl("product1.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 5,
  //   },
  //   {
  //     key: 2,
  //     image: ImageUrl("product2.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 4,
  //   },
  //   {
  //     key: 3,
  //     image: ImageUrl("product3.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 3,
  //   },
  //   {
  //     key: 4,
  //     image: ImageUrl("product4.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 3.2,
  //   },
  //   {
  //     key: 5,
  //     image: ImageUrl("product1.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 4.5,
  //   },
  //   {
  //     key: 6,
  //     image: ImageUrl("product2.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 5,
  //   },
  //   {
  //     key: 7,
  //     image: ImageUrl("product3.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 2.2,
  //   },
  //   {
  //     key: 8,
  //     image: ImageUrl("product4.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 5,
  //   },
  //   {
  //     key: 9,
  //     image: ImageUrl("product1.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 4.5,
  //   },
  //   {
  //     key: 10,
  //     image: ImageUrl("product2.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 3.5,
  //   },
  //   {
  //     key: 11,
  //     image: ImageUrl("product3.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 2.5,
  //   },
  //   {
  //     key: 12,
  //     image: ImageUrl("product4.png"),
  //     title: "Grand Lodge Past",
  //     ratingNumber: "10",
  //     price: "$180",
  //     rating: 2.5,
  //   },
  // ]);
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>Supply Store</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={20}>
            <StoreProduct StoreProductCards={products} />
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default SupplyStore;

import React, { useEffect, useState } from "react";
import { Row, Col, Image, Avatar, Button, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import ImageGrid from "../../components/imagegrid";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImageUrl } from "../../config/functions";
import { AD } from "../../config/constants/api";
import { Get } from "../../config/api";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

function AdvertiseBusiness() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [ads, setAds] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  // const images = [
  //   ImageUrl("service1.png"),
  //   ImageUrl("upcoming4.png"),
  //   ImageUrl("upcoming3.png"),
  // ];
  // const [onlineService, setOnlineServices] = useState([
  //   {
  //     key: 1,
  //     title: "Dummy Online Services",
  //     avatar: ImageUrl("service1.png"),
  //     avatarName: "ADMINRM",
  //     avatarTimeDate: "02 days ago   I  08:00 AM",
  //     text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     bottomTitle: "Contact Details",
  //     contactInfo: "+012 345 6789",
  //   },
  //   {
  //     key: 2,
  //     title: "Dummy Online Services",
  //     avatar: ImageUrl("service1.png"),
  //     avatarName: "ADMINRM",
  //     avatarTimeDate: "02 days ago   I  08:00 AM",
  //     text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     bottomTitle: "Contact Details",
  //     contactInfo: "+012 345 6789",
  //   },
  //   {
  //     key: 3,
  //     title: "Dummy Online Services",
  //     avatar: ImageUrl("service1.png"),
  //     avatarName: "ADMINRM",
  //     avatarTimeDate: "02 days ago   I  08:00 AM",
  //     text: "Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  //     bottomTitle: "Contact Details",
  //     contactInfo: "+012 345 6789",
  //   },
  // ]);

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = `Showing records ${endIndex} of ${paginationConfig.totalDocs}`;

  const handlePageChange = (pageNumber) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: pageNumber,
    });

    getAllAds(pageNumber);
  };

  const handleLimitChange = (pageSize) => {
    setPaginationConfig({
      ...paginationConfig,
      limit: pageSize,
      current: 1,
    });

    getAllAds(1, pageSize);
  };

  const getAllAds = async (pageNumber, pageSize, search, reset = false) => {
    setLoading(true);
    try {
      const response = await Get(AD.getAllAds, token, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
        // status: reset ? "" : filter.status || null,
        // keyword: search ? search : null,
        // from: reset ? "" : filter?.from ? filter?.from.toISOString() : "",
        // to: reset ? "" : filter?.to ? filter?.to.toISOString() : "",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setAds(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllAds();
  }, []);

  const itemRender = (_, type, originalElement) => {
    if (type === "prev") {
      return <a>Previous</a>;
    }
    if (type === "next") {
      return <a>Next</a>;
    }
    return originalElement;
  };
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="space-between" align={"middle"}>
              <Col xs={2} md={2} xl={1}>
                <AiOutlineArrowLeft
                  style={{ fontSize: "25px" }}
                  className="cursor-pointer"
                  onClick={() => navigate(-1)}
                />
              </Col>
              <Col xs={22} md={19} xl={19}>
                <h2>Advertise Business </h2>
              </Col>
              <Col xs={22} md={4} xl={3} justify={"end"}>
                <Button
                  className="mainbtn"
                  onClick={() => navigate("/createadd")}
                >
                  Create Ad
                </Button>{" "}
              </Col>
            </Row>
            <Row justify="center">
              <Col xs={22} md={18} xl={16}>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua
                </p>
              </Col>
            </Row>
            {ads.length > 0 &&
              ads.map((item, index) => {
                return (
                  <Row
                    justify="center"
                    gutter={30}
                    className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
                    key={index}
                  >
                    <Col xs={22} md={22} lg={11}>
                      <ImageGrid smallImages={item?.images} />
                    </Col>
                    <Col xs={22} md={22} lg={11}>
                      <h3>{item?.title}</h3>
                      <Row gutter={20} className="margin-y-15">
                        <Col>
                          <Avatar
                            size={48}
                            icon={
                              <Image preview={false} src={item.author.image} />
                            }
                          />
                        </Col>
                        <Col>
                          <h5 className="margin-0">{item.author.fullName}</h5>
                          <p>{dayjs(item.createdAt).format("M/D/YYYY")}</p>
                        </Col>
                      </Row>
                      <p>{item.description}</p>
                      <h5>{item.email}</h5>
                      <p>{item.phone}</p>
                    </Col>
                  </Row>
                );
              })}
            <Row style={{ padding: "10px 0px" }}>
              <Col xs={24} md={12}>
                <p>{message}</p>
              </Col>
              <Col
                xs={24}
                md={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Pagination
                  className="styledPagination"
                  onChange={(e) => handlePageChange(e)}
                  current={parseInt(paginationConfig.pageNumber)}
                  pageSize={paginationConfig.limit}
                  total={paginationConfig.totalDocs}
                  itemRender={itemRender}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdvertiseBusiness;

import { Button, Col, Input, Modal, Radio, Row, Space } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../config/api";
import { RANK } from "../config/constants/api";
import { ImageUrl } from "../config/functions";

const FailureModal = ({ isModalOpen2, setIsModalOpen2, rankId }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [chances, setChances] = useState(0);
  const token = useSelector((state) => state.user.userToken);
  const handleOk = () => {
    setIsModalOpen2(false);
  };

  const handleOk2 = async () => {
    navigate(`/degree/${rankId}`);
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    navigate("/nonmasoniccomunity");
    setIsModalOpen2(false);
  };

  useEffect(() => {
    getChances();
  }, []);

  const getChances = async () => {
    setLoading(true);
    try {
      const response = await Get(`${RANK.getChances}`, token);
      if (response?.status) {
        setChances(response?.data?.rankChancesLeft);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen2}
        onOk={handleOk}
        onCancel={handleCancel2}
        footer={false}
      >
        <Row justify="center">
          <Col md={20}>
            <div className="flex justify-center my-4">
              <img src={ImageUrl("email.png")} />
            </div>
            <h3 className="uppercase text-black text-2xl font-bold text-center">
              your answer is incorrect!
            </h3>
            <p className="text-xs text-gray-600 my-4 text-center font-roboto">
              You Have {chances} Chance, Do Want To Continue ?
            </p>
            <div className="flex justify-center items-center">
              <Button className="mainbtn padding-x-40" onClick={handleOk2}>
                Yes
              </Button>
              <Button
                className="mainbtn padding-x-50 margin-x-10"
                onClick={handleCancel2}
              >
                Later
              </Button>
            </div>
            <p className="text-xs text-gray-600 my-3 text-center font-roboto leading-8">
              Upon Failing To Access This Ritual, We You Suggest That You Join A
              Respectable Lodge For Advancement, Training And Self Development.{" "}
              <br /> Sincerely. <br /> Ritual Masonic1
            </p>
          </Col>
        </Row>
        {/* <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Your answer is incorrect!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>You Have A Second And Last Chance, Do Want To Continue ?</p>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                className="mainbtn padding-x-40"
                onClick={() => navigate("/librarycontent")}
              >
                Yes
              </Button>
              <Button
                className="mainbtn padding-x-50 margin-x-10"
                onClick={handleCancel2}
              >
                Later
              </Button>
            </div>
          </Col>
          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>
                Upon Failing To Access This Ritual, We You Suggest That You Join
                A Respectable Lodge For Advancement, Training And Self
                Development.
              </p>
            </div>
          </Col>
        </Row> */}
      </Modal>
    </div>
  );
};

export default FailureModal;

import React from "react";
import { Row, Col, Image, Card, Rate } from "antd";
import { useNavigate } from "react-router";
import { UPLOADS_URL } from "../../config/constants/api";

function StoreProduct({ StoreProductCards, StoreProductClass }) {
  const navigate = useNavigate();
  return (
    <>
      {StoreProductCards?.length > 0 &&
        StoreProductCards?.map((item, index) => {
          return (
            <Col xs={24} md={8} lg={6}>
              <div
                className="productcard"
                onClick={() => navigate("/product-details/" + item._id)}
              >
                <Card>
                  <Image preview={false} src={UPLOADS_URL + "/" + item?.gallery[0]} />
                  <h5>{item.title}</h5>
                  <div style={{ textAlign: "left" }}>
                    <Rate defaultValue={item.rating} />
                    <span
                      className="ant-rate-text"
                      style={{ marginLeft: "10px" }}
                    >
                      {item.ratingNumber}{" "}
                    </span>
                  </div>
                  <h6>${item.price}</h6>
                </Card>
              </div>
            </Col>
          );
        })}
    </>
  );
}

export default StoreProduct;

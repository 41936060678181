import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  subTotal: 0,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      const { product, quantity } = payload;
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.title === product.title
      );
      if (existingItemIndex !== -1) {
        state.cartItems[existingItemIndex].quantity += quantity;
      } else {
        state.cartItems.push({ product, quantity, productType: "product" });
      }
      state.subTotal += quantity * product.price;
    },
    addBookToCart: (state, { payload }) => {
      const { book, quantity, bookType } = payload;
      console.log(book);

      if (!book || !quantity || !bookType) return; // Basic validation

      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.title === book.title
      );

      if (existingItemIndex !== -1) {
        state.cartItems[existingItemIndex].quantity += quantity;
      } else {
        state.cartItems.push({ product: book, quantity, productType: "book" });
      }

      switch (bookType) {
        case "HARDCOPY":
          state.subTotal += quantity * (book.price || 0);
          break;
        case "EBOOK":
          state.subTotal += quantity * (book.ebookPrice || 0);
          break;
        default:
          break; // Handle any other cases if needed
      }
      console.log(state);
    },
    removeFromCart: (state, { payload }) => {
      const { product } = payload;
      const itemToRemove = state.cartItems.find(
        (item) => item.title === product.title
      );
      if (itemToRemove) {
        state.subTotal -= itemToRemove.quantity * itemToRemove.price;
        state.cartItems = state.cartItems.filter(
          (item) => item.title !== product.title
        );
      }
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.subTotal = 0;
    },

    updateCartItemQuantity: (state, { payload }) => {
      const { name, quantity } = payload;
      const itemToUpdate = state.cartItems.find((item) => item.name === name);
      if (itemToUpdate) {
        const quantityDifference = quantity - itemToUpdate.quantity;
        itemToUpdate.quantity = quantity;
        state.subTotal += quantityDifference * itemToUpdate.price;
      }
      state.subTotal = state.cartItems.reduce((accumulator, product) => {
        return accumulator + product.price;
      }, 0);
      debugger;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  clearCart,
  updateCartItemQuantity,
  addBookToCart,
} = cartSlice.actions;

export default cartSlice.reducer;

import React from "react";
import { Col, Row, Image, Rate, Avatar } from "antd";
import AliceCarousel from "react-alice-carousel";
import { ImageUrl } from "../../config/functions";

const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const items = [
  <div className="item" data-value="1">
    <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Lorum Ipsum Dummy</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("profileicon.png")} />}
          />
        </Col>
        <Col>
          <h4>David Guetta</h4>
          <span>Songrk live, nyc</span>
        </Col>
      </Row>
    </div>
  </div>,
  <div className="item" data-value="1">
    <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Lorum Ipsum Dummy</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("profileicon.png")} />}
          />
        </Col>
        <Col>
          <h4>David Guetta</h4>
          <span>Songrk live, nyc</span>
        </Col>
      </Row>
    </div>
  </div>,
  <div className="item" data-value="1">
    <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Lorum Ipsum Dummy</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("profileicon.png")} />}
          />
        </Col>
        <Col>
          <h4>David Guetta</h4>
          <span>Songrk live, nyc</span>
        </Col>
      </Row>
    </div>
  </div>,
  <div className="item" data-value="1">
    <div className="clientreview ">
      <Rate allowHalf defaultValue={4.5} />
      <h3>Lorum Ipsum Dummy</h3>
      <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      <Row gutter={20} className="margin-y-15">
        <Col>
          <Avatar
            size={48}
            icon={<Image preview={false} src={ImageUrl("profileicon.png")} />}
          />
        </Col>
        <Col>
          <h4>David Guetta</h4>
          <span>Songrk live, nyc</span>
        </Col>
      </Row>
    </div>
  </div>,
];

const CustomCard = ({ comment, rating, name, image }) => {
  return (
    <div className="item" data-value="1">
      <div className="clientreview ">
        <Rate allowHalf defaultValue={rating} />
        <p>{comment}</p>
        <Row gutter={20} className="margin-y-15">
          <Col>
            <Avatar
              size={48}
              icon={<Image preview={false} src={ImageUrl("avatar.png")} />}
            />
          </Col>
          <Col>
            <h4>{name}</h4>
            {/* <span>Songrk live, nyc</span> */}
          </Col>
        </Row>
      </div>
    </div>
  );
};

// function ReviewCaurosel({ reviews }) {
//   return (
//     <div>
//       <AliceCarousel
//         mouseTracking
//         items={items}
//         responsive={responsive}
//         disableButtonsControls={true}
//         disableDotsControls
//         controlsStrategy="alternate"
//         paddingLeft={0}
//         paddingRight={0}
//         infinite
//         autoPlay
//         autoPlayInterval={800}
//       />
//     </div>
//   );
// }

function ReviewCaurosel({ reviews }) {
  return (
    <div>
      <AliceCarousel
        mouseTracking
        items={reviews?.map((review, index) => (
          <CustomCard
            comment={review.comment}
            rating={review.rating}
            name={review.user.fullName}
          />
        ))}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        paddingLeft={0}
        paddingRight={0}
        infinite
        autoPlay
        autoPlayInterval={800}
      />
    </div>
  );
}
export default ReviewCaurosel;

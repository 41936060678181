import React, { useEffect, useState } from "react";
import Subscription from "../../components/subscription";
import { Row, Col, Image } from "antd";
import about1 from "../../assets/about1.png";
import { ImageUrl } from "../../config/functions";
import { CONTENT, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api";

function About() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const getContent = async () => {
    setLoading(true);
    try {
      const response = await Get(CONTENT.getAboutContent);
      if (response?.status) {
        setContent(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <h2>
                  {content?.heading ? content.heading : "About Ritual Masonic"}
                </h2>
                <p>
                  {content?.description
                    ? content.description
                    : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua"}
                </p>
              </Col>
            </Row>
            <Row
              justify="center"
              gutter={50}
              className="info-area padding-y-40"
            >
              <Col xs={22} md={22} lg={11}>
                <Image
                  preview={false}
                  src={
                    content?.section1?.image
                      ? UPLOADS_URL + "/" + content.section1.image
                      : ImageUrl("about2.png")
                  }
                />
              </Col>
              <Col xs={22} md={22} lg={11}>
                <h3>
                  {content?.section1?.heading
                    ? content?.section1.heading
                    : "Our Goals"}
                </h3>
                <p>
                  {content?.section1?.description
                    ? content?.section1.description
                    : "Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                </p>
                {/* <p>
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                </p>
                <p>
                  It was popularised in the with the release of Letraset sheets
                  containing.
                </p>
                <p>
                  Lorem Ipsum passages, and more recently with desktop
                  publishing software like Aldus PageMaker including versions of
                  Lorem Ipsum.
                </p> */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="center" className="greybg padding-y-30">
          <Col xs={22} md={20} xl={18}>
            <Row
              justify="center"
              gutter={50}
              className="info-area padding-y-40"
            >
              <Col xs={22} md={22} lg={11}>
                <h3>
                  {content?.section2?.heading
                    ? content?.section2.heading
                    : "Mission & Vision"}
                </h3>
                <p>
                  {content?.section2?.description
                    ? content?.section2.description
                    : "Lorem Ipsum is simply dummy text of the printing and  typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book."}
                </p>
              </Col>
              <Col xs={22} md={22} lg={11}>
                <Image
                  preview={false}
                  src={
                    content?.section2?.image
                      ? UPLOADS_URL + "/" + content.section2.image
                      : ImageUrl("about2.png")
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Subscription />
    </>
  );
}

export default About;

import React, {useState} from 'react'
import Banner from '../../components/banner'
import VideoArea from'../../components/videoarea'
import UpcomingEvenets from '../../components/upcoming'
import FeaturedBooks from '../../components/featuredBooks'
import RightsArea from '../../components/rightsarea'
import Subscription from '../../components/subscription'
import BannerIcons from '../../components/banner-icons'
import BannerIcon1 from '../../assets/banner-icon-1.png'
import BannerIcon2 from '../../assets/banner-icon-2.png'
import BannerIcon3 from '../../assets/banner-icon-3.png'
import BannerIcon4 from '../../assets/banner-icon-4.png'
import BannerIcon5 from '../../assets/banner-icon-5.png'
import BannerIcon6 from '../../assets/banner-icon-6.png'



function Home() {
  const [bannerCards, setBannerCards] = useState([
    {
      key: 1,
      image: BannerIcon1,
      title: "Supply Store",
      link: "/supply-store"  
    },
    {
      key: 2,
      image: BannerIcon2,
      title: "Member Community",
      link: "/nonmasoniccomunity"
    },
    {
      key: 3,
      image: BannerIcon3,
      title: "VIP Messaging",
    },
    {
      key: 4,
      image: BannerIcon4,
      title: "Library Access",
    },
    {
      key: 5,
      image: BannerIcon5,
      title: "Live Meeting Access",
      link: "/livestreaming"
    },
    {
      key: 6,
      image: BannerIcon6,
      title: "Advertise Business",
      link: "/advertisebusiness"
    },
  ]);
  return (
    <div>
        <Banner height={"80vh"} />
        <BannerIcons bannerCards={bannerCards} bannerIconClass="bannercards"/>
        <VideoArea/>
        <UpcomingEvenets/>
        <FeaturedBooks/>
        <RightsArea/>
        <Subscription/>
        
    </div>
  )
}

export default Home
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Radio } from "antd";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Get } from "../../config/api";
import { RANK } from "../../config/constants/api";
import { useSelector } from "react-redux";
import BookModal from "../../modals/BookModal";

function LibraryContentDetails() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const rankId = location.state?.rankId;
  const { topicId } = useParams();
  const [topicDetails, setTopicDetails] = useState({});
  const token = useSelector((state) => state.user.userToken);
  const [bookModal, setBookModal] = useState(false);
  const [bookId, setBookId] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (topicId && rankId) getTopicDetails();
  }, []);
  const getTopicDetails = async () => {
    setLoading(true);
    try {
      const response = await Get(
        `${RANK.getTopicDetails}${rankId}/${topicId}`,
        token
      );
      if (response?.status) {
        setTopicDetails(response?.data?.topicContent);
        setBookId(response?.data?.bookId);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <div className="librarydetails">
            <Row align="middle">
              <Col xs={24} md={24} lg={16} xl={19}>
                <h4>{topicDetails?.heading}</h4>
              </Col>
              <Col xs={24} md={24} lg={6} xl={5}>
                <Row justify="space-between">
                  <div
                    className="purchasebtn mousepointer"
                    onClick={() => setBookModal(true)}
                  >
                    Purchase this Book
                  </div>
                  <div>
                    <span className="mousepointer">
                      <u>{topicDetails?.topicId}</u>
                    </span>
                  </div>
                </Row>
              </Col>
              <Col xs={24}>
                <p>{topicDetails?.content}</p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {setBookModal && (
        <BookModal
          bookModal={bookModal}
          setBookModal={setBookModal}
          bookId={bookId}
        />
      )}
      {/* <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Congrats!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>Please Choose Book Type Below:</p>
              <Radio.Group>
                <Radio value={1}>I'm good with E-book</Radio>
                <Radio value={2}>I want a hardcopy</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                className="mainbtn padding-x-40"
                onClick={() => navigate("/check-out")}
              >
                Checkout
              </Button>
              <Button
                className="mainbtn padding-x-50 margin-x-10"
                onClick={handleCancel}
              >
                Later
              </Button>
            </div>
          </Col>
        </Row>
      </Modal> */}
    </>
  );
}

export default LibraryContentDetails;

import React, { useState } from "react";
import { Row, Col, Image, Avatar, Input, Button } from "antd";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { ImShare2 } from "react-icons/im";
import moment from "moment";
import { UPLOADS_URL, UPLOADS_URL2 } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../config/functions";
import parse from "html-react-parser";

function PostComponent({
  post,
  index,
  isCommentsOpen,
  onCommentClick,
  commentPost,
  likePost,
}) {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  console.log(token);
  const [selectedIndex, setSelectedIndex] = useState();
  const [comment, setComment] = useState("");

  return (
    <div>
      {post && (
        <Row
          justify="center"
          className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
        >
          <Col xs={23} md={22} xl={22}>
            <Row gutter={20} className="margin-y-15">
              <Col xs={5} md={4} xl={1}>
                <Avatar
                  size={48}
                  icon={
                    post?.author.image ? (
                      <Image
                        src={UPLOADS_URL + "/" + post?.author.image}
                        alt="Analytics Image"
                        preview={false}
                        className="abc"
                        width={"100%"}
                      />
                    ) : (
                      <Image preview={false} src={ImageUrl("avatar.png")} />
                    )
                  }
                />
              </Col>
              <Col xs={19} md={20} xl={23}>
                <h5 className="margin-0">
                  {post?.author.isAdmin ? "Admin" : post?.author.fullName}
                </h5>
                <p>Posted {moment(post.createdAt).fromNow()} </p>
              </Col>
            </Row>
          </Col>
          <Col xs={23} md={22} xl={22}>
            <p> {parse(post?.title)}</p>

            <div className="postimage">
              {post.images.length > 0 && (
                <Col xs={24} md={24}>
                  <Image
                    src={UPLOADS_URL2 + post.images[0]}
                    alt="Analytics Image"
                    preview={false}
                    className="abc"
                    width={"100%"}
                  />
                </Col>
              )}

              <Row justify={"center"}>
                <Col xs={23}>
                  <Row justify={"space-around"}>
                    <Col
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ justifyContent: "start", display: "flex" }}
                    >
                      <span
                        className="gradient"
                        onClick={() => likePost(index)}
                      >
                        <AiOutlineLike fontSize={24} />
                        <span>{post.likes.length} Likes</span>
                      </span>
                    </Col>
                    <Col
                      xs={12}
                      md={12}
                      xl={12}
                      style={{ justifyContent: "end", display: "flex" }}
                    >
                      <span
                        className="gradient"
                        onClick={() => onCommentClick()}
                      >
                        <FaRegCommentDots fontSize={24} />
                        <span>{post.comments.length} Comments</span>
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            {isCommentsOpen && (
              <>
                <hr className="margin-y-20" />

                <div style={{ maxHeight: "200px", overflow: "auto" }}>
                  {post.comments.length > 0 &&
                    post.comments.map((item) => {
                      return (
                        <Row gutter={50} style={{ margin: "20px 10px" }}>
                          <Col xs={5} md={4} lg={2} xl={1}>
                            {" "}
                            <Avatar
                              size={30}
                              icon={
                                <Image
                                  preview={false}
                                  src={
                                    item.author.image
                                      ? UPLOADS_URL + "/" + item.author.image
                                      : ImageUrl("avatar.png")
                                  }
                                />
                              }
                            />
                          </Col>
                          <Col
                            xs={19}
                            md={20}
                            lg={22}
                            xl={23}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p style={{ margin: 0 }}>{item.text} </p>
                          </Col>
                        </Row>
                      );
                    })}
                </div>

                {token && (
                  <>
                    {" "}
                    <Row className="margin-y-30" gutter={20}>
                      <Col xs={5} md={4} lg={2} xl={1}>
                        {" "}
                        <Avatar
                          size={40}
                          icon={
                            <Image
                              preview={false}
                              src={
                                user.image
                                  ? UPLOADS_URL + "/" + user.image
                                  : ImageUrl("avatar.png")
                              }
                            />
                          }
                        />
                      </Col>
                      <Col xs={19} md={20} lg={22} xl={23}>
                        <Input
                          size="large"
                          style={{ borderRadius: "50px" }}
                          placeholder={`Comment as ${user.fullName}`}
                          className="border-radius-40"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row justify={"end"}>
                      <Col>
                        <Button
                          className="mainbtn padding-x-40"
                          onClick={() => {
                            commentPost(index, comment);
                            setComment("");
                          }}
                        >
                          Comment
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default PostComponent;

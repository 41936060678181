import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import userReducer from "./slice/authSlice";
import questionReducer from "./slice/questionSlice";
import cartReducer from "./slice/cartSlice";
import timerReducer from "./slice/timerSlice";

const rootReducer = combineReducers({
  user: userReducer,
  question: questionReducer,
  cart: cartReducer,
  timer: timerReducer,
});

const persistConfig = {
  key: "ritual_masoninc_user",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

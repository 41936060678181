import React, { useEffect, useState } from "react";
import { Row, Col, List, Image, message } from "antd";
import image1 from "../../assets/product4.png";
import { useDispatch, useSelector } from "react-redux";
import { ORDER, UPLOADS_URL } from "../../config/constants/api";
import { Post } from "../../config/api/post";
import { useNavigate } from "react-router-dom";
import { clearCart, removeFromCart } from "../../redux/slice/cartSlice";

const count = 3;

function ConfirmOrderDetails({ personalInfo, placeOrder }) {
  const [list, setList] = useState([]);
  const cartDetails = useSelector((state) => state.cart);
  const [orders, setOrders] = useState(cartDetails.cartItems);
  console.log(orders);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);

  const handlePlaceOrder = () => {
    setLoading(true);
    console.log(personalInfo);
    const orderInfo = {
      items: orders.map((item) => ({
        product: item.product._id,
        quantity: item.quantity,
        productType: item.productType,
        variation: item.product.variations?.map((variation) => ({
          type: variation.type,
          value: variation.value,
        })),
      })),
      shippingAddress: {
        address: personalInfo.shipping.address,
        country: personalInfo.shipping.country,
        city: personalInfo.shipping.city,
        state: personalInfo.shipping.state,
        zip: personalInfo.shipping.zip,
        phone: personalInfo.phone,
      },
      billingAddress: {
        address: personalInfo.billing.address,
        country: personalInfo.billing.country,
        city: personalInfo.billing.city,
        state: personalInfo.billing.state,
        zip: personalInfo.billing.zip,
        phone: personalInfo.phone,
      },
      isPaid: false,
      differentAddress: personalInfo.differentAddress,
    };

    Post(ORDER.addOrder, orderInfo, token)
      .then((response) => {
        setLoading(false);
        console.log(response);
        if (response?.data?.status) {
          message.success("Order placed successfully!");
          navigate("/home");
          dispatch(clearCart());
        } else {
          message.error(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (placeOrder) handlePlaceOrder();
  }, [placeOrder === true]);

  return (
    <div className="checkout">
      <h3>ConfirmOrderDetails</h3>
      <Row>
        <Col xs={24} md={24}>
          <List
            className="demo-loadmore-list"
            itemLayout="horizontal"
            dataSource={orders}
            renderItem={(item) => (
              <List.Item>
                <Row gutter={20}>
                  <Col>
                    <Image
                      preview={false}
                      src={
                        item.product?.gallery
                          ? UPLOADS_URL + "/" + item.product.gallery[0]
                          : UPLOADS_URL + "/" + item.product?.coverImage
                      }
                      width={"60px"}
                      height={"60px"}
                      style={{ objectFit: "contain" }}
                    />
                  </Col>
                  <Col>
                    <h6>{item.product.title}</h6>
                  </Col>
                  <Col>
                    <h5>$ {item.product.price * item.quantity}</h5>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ConfirmOrderDetails;

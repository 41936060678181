import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Checkbox,
  Button,
  Modal,
  Input,
  Space,
  Radio,
  Image,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Logo from "../../assets/logo.png";
import BannerIcons from "../../components/banner-icons";
import { ImageUrl } from "../../config/functions";
import { Get, Post } from "../../config/api";
import { RANK, UPLOADS_URL } from "../../config/constants/api";
import SuccessModal from "../../modals/SuccessModal";
import FailureModal from "../../modals/FailureModal";

function DegreeDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [stateChange, setStateChange] = useState(false);

  useEffect(() => {
    if (id) {
      getRanking();
    }
  }, [id]);

  const getRanking = async () => {
    setLoading(true);
    try {
      const response = await Get(`${RANK.getRank}${id}`, token);
      if (response?.status) {
        setRanking(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onChangeCheck = (checkedValues) => {
    setSelectedOptions(checkedValues);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Post(
        RANK.hasClearedTest,
        {
          appliedRank: ranking?.rank,
          markedOptions: selectedOptions,
        },
        token
      );
      if (response?.status) {
        if (response?.data?.data?.hasClearedTest) {
          setStateChange(true);
          setIsModalOpen(true);
        } else {
          setIsModalOpen2(true);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <Card className="reviewarea text-center">
                  <h3>
                    Welcome to Rituals Masonic1, brother, {user.fullName} and
                    thank you for taking the Q&A {ranking?.rankName} Apprentice
                  </h3>

                  <Row justify="center">
                    <Col xs={18} md={8}>
                      <Card className="bannercards3">
                        <Image
                          preview={false}
                          src={UPLOADS_URL + "/" + ranking?.rankImage}
                        />
                        <p>{ranking?.rank}</p>
                        <p>{ranking?.rankName}</p>
                      </Card>
                    </Col>
                  </Row>

                  <p className="text-left">
                    Brother,
                    <br />
                    {ranking?.question?.question} Responses are numerically
                    mark, and the proper responses will be added automatically
                    and the right result, will qualify you. If you fail, you
                    will be granted a second and last chance to be qualified.
                  </p>

                  <Checkbox.Group
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                    onChange={onChangeCheck}
                  >
                    <Row>
                      {ranking?.question?.options?.map((option, index) => (
                        <Col span={6} key={index}>
                          <Checkbox value={option}>{option}</Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>

                  <Button
                    className="mainbtn margin-y-30"
                    onClick={handleSubmit}
                  >
                    Submit Now!
                  </Button>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {isModalOpen && (
        <SuccessModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          degree={ranking?.degree}
          rankId={id}
          rank={ranking?.rank}
        />
      )}

      {isModalOpen2 && (
        <FailureModal
          isModalOpen2={isModalOpen2}
          setIsModalOpen2={setIsModalOpen2}
          rankId={id}
        />
      )}

      {/* <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {!stateChange ? (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>Your response was correct!</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Please Enter Your PCS</p>
              </div>
              <div>
                <Space.Compact
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                  }}
                >
                  <Input defaultValue="Your PCS Here" />
                  <Button type="primary" className="mainbtn">
                    Submit
                  </Button>
                </Space.Compact>
              </div>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>
                  Menu Option Will Be Shown Below: Please Click On, #1ARMNP
                  -Option
                </p>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button
                  className="mainbtn padding-x-50"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>RITUALS MASONIC1</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Welcome To #1ARMNP245-Option Menu, You Are Now Qualified.</p>
              </div>
              <div>
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Space direction="vertical">
                    <Radio value={1}>
                      Please Click On,{" "}
                      <span className="goldencolor">1ARMP</span> For Purchase,
                      This Action Should Send This Ritual To Cart
                    </Radio>
                    <Radio value={2}>
                      Please Click On,{" "}
                      <span className="goldencolor">1ARM-MTRO</span> For Menu
                      Topic To Read Only
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button
                  onClick={() => navigate("/library-content-first-rank-topic")}
                  className="mainbtn margin-y-40 padding-x-50"
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Modal> */}

      {/* <Modal
        open={isModalOpen2}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Your answer is incorrect!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>You Have A Second And Last Chance, Do Want To Continue ?</p>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                className="mainbtn padding-x-40"
                onClick={() => navigate("/librarycontent")}
              >
                Yes
              </Button>
              <Button
                className="mainbtn padding-x-50 margin-x-10"
                onClick={handleCancel2}
              >
                Later
              </Button>
            </div>
          </Col>
          <Col xs={22} md={22}>
            <div className="modalinner text-center">
              <p>
                Upon Failing To Access This Ritual, We You Suggest That You Join
                A Respectable Lodge For Advancement, Training And Self
                Development.
              </p>
            </div>
          </Col>
        </Row>
      </Modal> */}
    </>
  );
}

export default DegreeDetails;

import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Space,
} from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../config/api";
import { RANK } from "../config/constants/api";
import { ImageUrl } from "../config/functions";
import ConfirmationModal from "./ConfirmationModal";

const SuccessModal = ({
  degree,
  rankId,
  isModalOpen,
  setIsModalOpen,
  rank,
}) => {
  const [value, setValue] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await Get(`${RANK.isEligible}${degree}`, token, {
        PCS: values?.pcs,
      });

      if (response.status === true) {
        if (response?.data.viewContent) {
          setConfirmationModal(true);
        } else if (response?.data.viewTest) {
          navigate("/degree-details/" + rankId);
        }
      } else {
        message.error(response?.response?.data?.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {};
  return (
    <div>
      {" "}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify="center">
          <Col md={20}>
            <div className="flex justify-center my-4">
              <img src={ImageUrl("done.png")} />
            </div>
            <h3 className="uppercase text-black text-2xl font-bold text-center">
              Your response was correct!
            </h3>
            <p className="text-xs text-gray-600 my-4 text-center font-roboto">
              Please Enter Your PCS
            </p>
            <div>
              <Form form={form} onFinish={onFinish} className="customForm">
                <Space.Compact
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Form.Item
                    name="pcs"
                    rules={[
                      { required: true, message: "Please input your PCS!" },
                    ]}
                  >
                    <Input placeholder="Your PCS Here" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="mainbtn"
                      htmlType="submit"
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </div>
          </Col>
        </Row>
        {/* {!stateChange ? (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>Your response was correct!</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Please Enter Your PCS</p>
              </div>
              <div>
                <Space.Compact
                  style={{
                    width: "100%",
                    marginBottom: "25px",
                  }}
                >
                  <Input defaultValue="Your PCS Here" />
                  <Button type="primary" className="mainbtn">
                    Submit
                  </Button>
                </Space.Compact>
              </div>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>
                  Menu Option Will Be Shown Below: Please Click On, #1ARMNP
                  -Option
                </p>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button
                  className="mainbtn padding-x-50"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <h4>RITUALS MASONIC1</h4>
            </Col>

            <Col xs={22} md={22}>
              <div className="modalinner">
                <p>Welcome To #1ARMNP245-Option Menu, You Are Now Qualified.</p>
              </div>
              <div>
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Space direction="vertical">
                    <Radio value={1}>
                      Please Click On,{" "}
                      <span className="goldencolor">1ARMP</span> For Purchase,
                      This Action Should Send This Ritual To Cart
                    </Radio>
                    <Radio value={2}>
                      Please Click On,{" "}
                      <span className="goldencolor">1ARM-MTRO</span> For Menu
                      Topic To Read Only
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button
                  onClick={() => navigate("/library-content-first-rank-topic")}
                  className="mainbtn margin-y-40 padding-x-50"
                >
                  Next
                </Button>
              </div>
            </Col>
          </Row>
        )} */}
      </Modal>
      {confirmationModal && (
        <ConfirmationModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          rank={rank}
          rankId={rankId}
        />
      )}
    </div>
  );
};

export default SuccessModal;

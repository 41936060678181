import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import BannerIcons from "../../components/banner-icons";
import { ImageUrl } from "../../config/functions";
import ContentRestrictionModal from "../../modals/ContentRestrictionModal";
import { RANK } from "../../config/constants/api";
import { Get } from "../../config/api";
import { useSelector } from "react-redux";

function LibararyContent() {
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [ranks, setRanks] = useState([
    {
      key: 1,
      image: ImageUrl("banner-icon-7.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 2,
      image: ImageUrl("banner-icon-4.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 3,
      image: ImageUrl("banner-icon-8.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 4,
      image: ImageUrl("banner-icon-6.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 5,
      image: ImageUrl("banner-icon-7.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 6,
      image: ImageUrl("banner-icon-4.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 7,
      image: ImageUrl("banner-icon-8.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 8,
      image: ImageUrl("banner-icon-6.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 9,
      image: ImageUrl("banner-icon-7.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 10,
      image: ImageUrl("banner-icon-4.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 11,
      image: ImageUrl("banner-icon-8.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
    {
      key: 12,
      image: ImageUrl("banner-icon-6.png"),
      title: "Religious Articles",
      link: "/glory",
      des: "abc",
    },
  ]);

  const getAllRanks = async () => {
    setLoading(true);
    try {
      const response = await Get(RANK.getAllRank, token);
      if (response?.status) {
        setRanks(response?.data?.rankDetails);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllRanks();
  }, []);
  return (
    <div>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>Libarary Content</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40">
            <Col xs={22} md={22} lg={22}>
              <BannerIcons ranks={ranks} bannerIconClass="bannercards3" />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default LibararyContent;

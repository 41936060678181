import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image } from "antd";
import Logo from "../../assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../config/api";
import { RANK } from "../../config/constants/api";
import { useSelector } from "react-redux";

function Glory() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);

  useEffect(() => {
    if (id) {
      getRanking();
    }
  }, []);

  const getRanking = async () => {
    setLoading(true);
    try {
      const response = await Get(`${RANK.getRank}${id}`, token);
      if (response?.status) {
        setRanking(response?.data?.rank);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <Card className="reviewarea text-center">
                  <h3>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting
                  </h3>
                  <h6>Lorem Ipsum is simply</h6>
                  <p>Lorem Ipsum is simply dummy </p>
                  <Image width={150} src={Logo} preview={false} />
                  <p style={{ marginTop: "50px" }}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley
                  </p>
                  <h6
                    className="mousepointer"
                    onClick={() => navigate("/degree/" + id)}
                  >
                    {ranking}
                  </h6>
                  <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Lorem Ipsum is simply dummy{" "}
                  </p>
                  <p style={{ marginTop: "5px", marginBottom: "5px" }}>
                    Lorem Ipsum is simply dummy{" "}
                  </p>
                  <p style={{ marginTop: "5px" }}>
                    Lorem Ipsum is simply dummy{" "}
                  </p>
                  <p>
                    Lorem <span className="goldencolor"> {ranking}</span> Ipsum
                    is simply dummy{" "}
                  </p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Glory;

import React, { useState, useEffect } from "react";
import { Row, Col, Image, Button, message } from "antd";
import { SERVICE, EVENT, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import dayjs from "dayjs"
import {ImLocation} from 'react-icons/im';
import Packages from '../../components/packges';
import { ImageUrl } from "../../config/functions";


function UpcomingEvenetsPage() { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  console.log(events, "UUU");

  const handlePageChange = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: Number(e.selected) + 1,
    });

    getEvents(Number(e.selected) + 1);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async (pageNumber, pageSize, search, reset = false) => {
    setLoading(true);
    try {
      const response = await Get(EVENT.getAllEvents, token, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setEvents(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };


    const [UpcomingEvenetsPage, setUpcomingEvenetsPage] = useState([
        {
          key: 1,
          image: ImageUrl("upcoming1.png"),
          title: "Dummy Online Services",
          date:"26",
          month:"june",
          year:"2023",
          locationIcon: ImLocation,
          locationName:"Songrk live, nyc",
          text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
          bottomBtn:"View Details",
          onclick:() =>{
            navigate("/upcomingeventdetails")
          }

        },
        {
            key: 1,
            image: ImageUrl("upcoming2.png"),
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },
          {
            key: 1,
            image: ImageUrl("upcoming3.png"),
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },
          {
            key: 1,
            image: ImageUrl("upcoming4.png"),
            title: "Dummy Online Services",
            date:"26",
            month:"june",
            year:"2023",
            locationIcon: ImLocation,
            locationName:"Songrk live, nyc",
            text:"Lorem Ipsum is simply dummy text of the printing  and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
            bottomBtn:"View Details",
            onclick:() =>{
                navigate("/upcomingeventdetails")
              }
  
          },

      ]);
  return (
   <>
    <div>
        <Row justify="center"  className='whitebg'>
        <Col  xs={22} md={20} xl={18}>
            <Row justify="center"> 
            <Col  xs={22} md={20} xl={14}>
                <h2>Upcoming Events</h2>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua</p>
            </Col>
            </Row>
            {events.length > 0 &&
              events.map((item, index) => {
                return( <Row justify="center" className='info-area padding-y-40 margin-y-40 border-radius-15' key={index}>
                <Col xs={22} md={22} lg={11} className='upcomingimage' >
                    <div className='datetag'>
                    <h6>{dayjs(item?.date).format("DD")}</h6>
                        <span>{dayjs(item?.date).format("MMM")}</span>
                        <span>{dayjs(item?.date).format("YYYY")}</span>
                    </div>
                <Image height={500} style={{objectFit:'cover',borderRadius:"20px"}} preview={false} src={UPLOADS_URL + "/" + item?.image} />
                    
                    
                    </Col>
                <Col xs={22} md={22} lg={11} className='shadowwhitebg padding-y-40  padding-x-40  border-radius-15'>
                    <h3 className='margin-y-10'>{item?.title}</h3>
                    <Row>
                        
                        <Col>
                            <span className='margin-0 goldencolor'><ImLocation/> {item?.location}</span>
                        </Col>
                    </Row>
                    <p className='margin-y-20'>{item?.description.slice(0,250)}...</p>
                    <Button className="mainbtn" onClick={item?.onclick}>View Details</Button>
                </Col>
            </Row>);
               
              })}

{events.length > 0 &&   <ReactPaginate
              breakLabel="..."
              nextLabel={<FaArrowRight style={{ color: "grey" }} />}
              pageRangeDisplayed={window.innerWidth > 500 ? 4 : 1}
              marginPagesDisplayed={window.innerWidth > 500 ? 4 : 1} //handle Pa
              onPageChange={handlePageChange}
              pageCount={paginationConfig?.totalPages}
              forcePage={paginationConfig?.pageNumber - 1}
              previousLabel={<FaArrowLeft style={{ color: "grey" }} />}
              renderOnZeroPageCount={null}
              pageClassName="page-item" //m
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="paginationContainer"
              activeClassName="active"
            />}
            
            
        </Col>
    
        
       
        </Row>

        {/* <Packages/>  */}

      </div>
   </>
  )
}

export default UpcomingEvenetsPage
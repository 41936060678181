import React, { useState } from "react";
import { Input, Button, Image, Modal, Typography } from "antd";
import { ImageUrl } from "../../config/functions";

function PCS() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {" "}
      <Modal
        className="pcsModal"
        centered
        closable={false}
        footer={false}
        open={isModalOpen}
      >
        <div
          style={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Image preview={false} src={ImageUrl("logo.png")} width={80} />
          <br />
          <Typography.Title
            className="fontfamily1"
            style={{ fontSize: "22px" }}
          >
            Welcome to Ritual Masonic 1
          </Typography.Title>
          <Typography.Text className="fontfamily1">
            Please Enter Your PCS (_00000000_) to Continue
          </Typography.Text>
          <br />
          <Input size="large" placeholder="_00000000_" />
          <br />
          <Button className="mainbtn" onClick={() => handleOk()}>
            Verify
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default PCS;

import { Button, Col, message, Modal, Radio, Row, Space } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/functions";
import { addBookToCart, addToCart } from "../redux/slice/cartSlice";
import { Get } from "../config/api";
import { BOOK } from "../config/constants/api";

const BookModal = ({ bookModal, setBookModal, bookId }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState({});
  const token = useSelector((state) => state.user.userToken);

  useEffect(() => {
    if (bookId) {
      getBookDetails();
    }
  }, [bookId]);

  const getBookDetails = async () => {
    setLoading(true);
    try {
      const response = await Get(`${BOOK.getBookById}${bookId}`, token);
      if (response?.status) {
        setBook(response.data.book);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setBookModal(false);
  };

  const handleCancel = () => {
    setBookModal(false);
  };

  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value === 1) {
      dispatch(
        addBookToCart({
          book,
          quantity: 1,
          bookType: "EBOOK",
        })
      );
    } else if (value === 2) {
      dispatch(
        addBookToCart({
          book,
          quantity: 1,
          bookType: "HARDCOPY",
        })
      );
    }
    navigate("/check-out");
    setBookModal(false);
  };

  return (
    <div>
      <Modal
        open={bookModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {!loading && (
          <Row justify={"center"}>
            <Col xs={22} md={22}>
              <div className="flex justify-center my-4">
                <img src={ImageUrl("done.png")} />
              </div>
              <h3 className="uppercase text-black text-2xl font-bold text-center">
                Congrats!
              </h3>
              <p className="text-sm text-gray-600 mt-4 text-center font-roboto capitalize">
                Please Choose Book Type Below:
              </p>
              <div className="flex justify-center my-6">
                <Radio.Group
                  onChange={onChangeRadio}
                  value={value}
                  className="customRadio"
                >
                  <Space direction="vertical">
                    <Radio
                      value={1}
                      className="text-sm text-gray-600  font-roboto capitalize"
                    >
                      I'm good with E-book
                    </Radio>
                    <Radio
                      value={2}
                      className="text-sm text-gray-600 font-roboto capitalize"
                    >
                      I want a hardcopy
                    </Radio>
                  </Space>
                </Radio.Group>
              </div>
            </Col>

            <Col xs={22} md={22} style={{ textAlign: "center" }}>
              <div className="modalinner">
                <Button className="mainbtn padding-x-40" onClick={handleSubmit}>
                  Checkout
                </Button>
                <Button
                  className="mainbtn padding-x-50 margin-x-10"
                  onClick={handleCancel}
                >
                  Later
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Modal>
    </div>
  );
};

export default BookModal;

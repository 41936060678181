import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Button } from "antd";
import { Player } from "video-react";
import { ImageUrl } from "../../config/functions";
import { BASE_URL, URL } from "../../config/constants/api";
import { io } from "socket.io-client";
import { ZegoUIKitPrebuilt } from "@zegocloud/zego-uikit-prebuilt";

function randomID(len) {
  let result = "";
  if (result) return result;
  var chars = "12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP",
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}

function LiveStreaming() {
  const roomID = "12345";
  const role = ZegoUIKitPrebuilt.Audience;
  let sharedLinks = [];
  sharedLinks.push({
    name: "Join as audience",
    url:
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?roomID=" +
      roomID +
      "&role=Audience",
  });
  // generate Kit Token
  const appID = 797150140;
  const serverSecret = "6e1b3c939306b8f4f2360252854ceeea";

  const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(
    appID,
    serverSecret,
    roomID,
    randomID(5),
    randomID(5)
  );

  // start the call
  let myMeeting = async (element) => {
    // Create instance object from Kit Token.
    const zp = ZegoUIKitPrebuilt.create(kitToken);
    // start the call
    zp.joinRoom({
      container: element,
      scenario: {
        mode: ZegoUIKitPrebuilt.LiveStreaming,
        config: {
          role,
        },
      },
      sharedLinks,
    });
  };
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>Live Meeting</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40 tnc">
            <Col xs={22} md={22} lg={22}>
              <div
                className="myCallContainer"
                ref={myMeeting}
                //   style={{ width: "100vw", height: "100vh" }}
              ></div>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col xs={24} md={24}>
              <div className="am-box">
                {/* <Image
                  src={ImageUrl("live-strem.png")}
                  alt="Analytics Image"
                  preview={false}
                /> */}
                {/* <video ref={videoRef} autoPlay></video> */}
                {/* <div className="a1">
                  <Button
                    type="button"
                    size={"large"}
                    style={{ padding: "12px 40px", height: "auto" }}
                    className="mainbtn"
                    onClick={checkStream}
                  >
                    Watch Live Stream
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default LiveStreaming;

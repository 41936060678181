import { Button, Col, Modal, Radio, Row, Space } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImageUrl } from "../config/functions";

const ConfirmationModal = ({
  confirmationModal,
  setConfirmationModal,
  rank,
  rankId
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const handleOk = () => {
    setConfirmationModal(false);
  };

  const handleCancel = () => {
    setConfirmationModal(false);
    navigate("/home");
  };

  const onChangeRadio = (e) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value === 1) {
      navigate("/home");
    } else if (value === 2) {
      navigate("/library-content-first-rank-topic/" + rankId);
    }
    setConfirmationModal(false);
  };

  return (
    <div>
      <Modal
        open={confirmationModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <div className="flex justify-center my-4">
              <img src={ImageUrl("done.png")} />
            </div>
            <h3 className="uppercase text-black text-2xl font-bold text-center">
              Ritual Masonic1
            </h3>
            <p className="text-sm text-gray-600 my-4 text-center font-roboto capitalize">
              Welcome to {rank} - Option Menu, You are now qualified.
            </p>
            <div>
              <Radio.Group onChange={onChangeRadio} value={value} className="customRadio">
                <Space direction="vertical">
                  <Radio
                    value={1}
                    className="text-sm text-gray-600  font-roboto capitalize"
                  >
                    Please Click On, <span className="goldencolor">{rank}</span>{" "}
                    For Purchase, This Action Should Send This Ritual To Cart
                  </Radio>
                  <Radio
                    value={2}
                    className="text-sm text-gray-600 font-roboto capitalize"
                  >
                    Please Click On, <span className="goldencolor">{rank}</span>{" "}
                    For Menu Topic To Read Only
                  </Radio>
                </Space>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                onClick={handleSubmit}
                className="mainbtn margin-y-40 padding-x-50"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

// export const BASE_URL = "https://secure.demo243.webhostlabs.net:3002/api/"
// export const UPLOADS_URL = "https://secure.demo243.webhostlabs.net:3002/Uploads/"

// import ChangePassword from "../../views/change-password"
const { NODE_ENV } = process.env;
const { hostname } = window.location;

const servers = {
  local: "http://localhost:3009",
  customDev: "https://react.customdev.solutions:3009",
  live: "https://ritualsmasonic12.com:3009",
};

var URL;
if (NODE_ENV === "production" && hostname.includes("react.customdev.solutions"))
  URL = servers.customDev;
else if (NODE_ENV === "production" && hostname.includes("ritualsmasonic12.com")) URL = servers.live;
else URL = servers.local;

export const BASE_URL = URL + "/api";
export const UPLOADS_URL = URL + "/Uploads";
export const UPLOADS_URL2 = URL + "/";

// export const URL = "http://localhost:3009";
// export const BASE_URL = "http://localhost:3009/api";
// export const UPLOADS_URL = "http://localhost:3009/Uploads";
// export const UPLOADS_URL2 = "http://localhost:3009/";

// export const BASE_URL = "https://react.customdev.solutions:3009/api"
// export const UPLOADS_URL = "https://react.customdev.solutions:3009/Uploads"
// export const UPLOADS_URL2 = "https://react.customdev.solutions:3009/"

export const AUTH = {
  signin: "/auth/signin",
  signup: "/auth/signup",
  emailCode: "/auth/emailVerificationCode",
  verifyCode: "/auth/verifyRecoverCode",
  resetPassword: "/auth/resetPassword",
};

export const SIGNUPFORM = {
  getSingleQuestion: "/signupForm/getQuestionByStep/",
};

export const PLANS = {
  getAllPlans: "/plan/getAllPlans",
};

export const POST = {
  addPost: "/post/addPost",
  getAllNonMemberPosts: "/post/getAllNonMemberPosts",
  getAllMemberPosts: "/post/getAllMemberPosts",
  commentPost: "/post/comment/",
  likePost: "/post/likePost/",
};

export const USER = {
  updateProfile: "/profile/updateProfile",
};

export const PRODUCT = {
  getAllProducts: "/product/getAllProducts",
  getProductById: "/product/getProductById/",
};

export const REVIEW = {
  getProductRating: "/review/getProductRating/",
  getAllReviewsByProductId: "/review/getAllReviewsByProductId/",
  addReview: "/review/addReview/",
};

export const AD = {
  createAd: "/ad/createAd",
  getAllAds: "/ad/getAllAds",
  getAdById: "/ad/getAdById/",
};

export const CONTACT = {
  addFeedback: "/feedback/addFeedback",
};
export const ORDER = {
  addOrder: "/order/addOrder/",
};

export const CONTENT = {
  getHomeContent: "/content/home/getContent/668e995343b0d55c4929ff66",
  getAboutContent: "/content/about/getContent/668eb4073cdc4369bcad9913",
  getTermsContent:
    "/content/termsAndConditions/getContent/668eed74836c9a14e3d23dd4",
};

export const ARTICLE = {
  getAllArticles: "/article/getAllArticles/",
};

export const RANK = {
  getAllRank: "/rank/getAllRankings",
  getRank: "/rank/getRank/",
  getTopicList: "/rank/getTopicsList/",
  isEligible: "/rank/isEligible/",
  hasClearedTest: "/rank/hasClearedTest",
  getChances: "/rank/getChancesLeft",
  getTopicDetails: "/rank/getTopicDetails/",
};
export const SERVICE = {
  addService: "/service/addService",
  updateService: "/service/updateService/",
  getAllServices: "/service/getAllServices",
  getServiceById: "/service/getServiceById/",
  deleteService: "/service/deleteService/",
  toggleStatus: "/service/toggleStatus",
};

export const EVENT = {
  addEvent: "/event/addEvent",
  updateEvent: "/event/updateEvent/",
  getAllEvents: "/event/getAllEvents",
  getEventById: "/event/getEventById/",
  deleteEvent: "/event/deleteEvent/",
  toggleStatus: "/event/toggleStatus",
};

export const BOOK = {
  addBook: "/book/addBook",
  updateBook: "/book/updateBook/",
  getAllBooks: "/book/getAllBooks",
  getBookById: "/book/getBookById/",
  deleteBook: "/book/deleteBook/",
};

export const STUDENT = {
  updateProfile: "/profile/updateProfile",
  changePassword: "/profile/changePassword",
  getMyCoaches: "/profile/getMyCoaches",
};

export const USERS = {
  get: "/auth/signin",
  getAllCoaches: "/admin/user/getAllCoaches",
  getAllTutors: "/admin/user/getAllTutors",
  getCoachById: "/admin/user/getCoachById/",
};

export const COACH = {
  updateProfile: "/coach/profile/updateProfile",
  changePassword: "/coach/profile/changePassword",
};

export const SERVICES = {
  getAll: "/service/getAllServices",
};

export const RATES = {
  getMyRates: "/rates/getMyRates",
  setRates: "/rates/setRates",
};

export const COMISSSION = {
  getComission: "/comission/getComission",
};

export const SCHEDULE = {
  addSchedule: "/schedule/addSchedule",
  getMySchedule: "/schedule/getMySchedule",
  getScheduleByCoachId: "/schedule/getScheduleByCoachId/",
};

export const REVIEWS = {
  getAll: "/review/getAllReviewsByCoachId/",
  getCoachRatings: "/review/getCoachRatings/",
};

export const LESSON = {
  bookLesson: "/lesson/addLesson",
  getUpcomingLessons: "/lesson/getAllUpcomingLessons",
  getCompletedLessons: "/lesson/getAllCompletedLessons",
  getLiveLessons: "/lesson/getAllLiveLessons",
  getLessonById: "/lesson/getLessonById/",
};

export const PAYMENT = {
  lessonPayment: "/payment/lessonPayment",
};

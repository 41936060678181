import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Upload,
  InputNumber,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { UploadOutlined } from "@ant-design/icons";
import { Post } from "../../config/api";
import { useSelector } from "react-redux";
import { CONTENT_TYPE } from "../../config/constants";
import swal from "sweetalert";
import { AD } from "../../config/constants/api";

function CreateAdd() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = useSelector((state) => state.user.userToken);
  const [fileList, setFileList] = useState([]);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    setLoading(true);

    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("email", values.email);
    formData.append("phone", values.phone);

    fileList.forEach((file) => {
      formData.append("images", file);
    });

    try {
      const response = await Post(
        AD.createAd,
        formData,
        token,
        null,
        CONTENT_TYPE.FORM_DATA
      );
      if (response?.status) {
        message.success("Ad submitted successfully!");
        swal(
          "System Message!",
          "Your Ad Has Been Submitted To The Admin! You'll Be Notified Whether It Is Approved Or Disapproved..",
          "Okay"
        );
        navigate("/advertisebusiness");
      }
    } catch (error) {
      console.error("Error submitting ad:", error);
      message.error("Failed to submit ad.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ fileList }) => {
    setFileList(fileList.map((file) => file.originFileObj));
  };

  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>
                <AiOutlineArrowLeft
                  onClick={() => navigate("/advertisebusiness")}
                />{" "}
                Create Ads
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22} md={20} xl={12}>
              <div className="shadowwhitebg padding-x-30 padding-y-40 margin-0 margin-y-40 border-radius-15">
                <div className="authFormBox">
                  <Row style={{ width: "100%", justifyContent: "center" }}>
                    <Col xs={24} md={24} className="formWrap">
                      <br />
                      <Form
                        layout="vertical"
                        name="basic"
                        className="loginForm"
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Form.Item
                          label="Advertisement Title"
                          name="title"
                          rules={[
                            {
                              required: true,
                              message: "Advertisement Title is required",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            className="AuthFormInput"
                            placeholder="Enter Title"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Upload Image"
                          name="images"
                          rules={[
                            { required: true, message: "Please Upload Image!" },
                          ]}
                        >
                          <Upload
                            multiple
                            beforeUpload={(file) => {
                              setFileList((prevList) => [...prevList, file]);
                              return false;
                            }}
                            onRemove={(file) => {
                              setFileList((prevList) =>
                                prevList.filter((item) => item.uid !== file.uid)
                              );
                            }}
                            fileList={fileList.map((file) => ({
                              uid: file.uid,
                              name: file.name,
                              status: "done",
                            }))}
                          >
                            <Button icon={<UploadOutlined />}>
                              +Upload Image
                            </Button>
                          </Upload>
                        </Form.Item>

                        <Form.Item
                          label="Description"
                          name="description"
                          rules={[
                            {
                              required: true,
                              message: "Description is required",
                            },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter Description Here!" />
                        </Form.Item>

                        <Form.Item
                          label="Email Address"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Enter a valid Email Address",
                            },
                            {
                              required: true,
                              message: "Email Address is required",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Email Address"
                            className="AuthFormInput"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Phone Number"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Phone Number is required",
                            },
                          ]}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Enter Phone Number"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <br />

                        <Form.Item style={{ textAlign: "center" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="loginButton mainbtn"
                            loading={loading}
                          >
                            {loading ? "Loading..." : "Post Ad"}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default CreateAdd;

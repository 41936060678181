import React from "react";
// import AuthLayout from "../../components/";
import { Col, Row, Typography, Form, Layout, Input, Button, Card } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";

// import router from "next/router";

function ForgetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in
  React.useEffect(() => {
    if (user && token) {
      navigate("/", { replace: true });
    }
  }, [user, token]);

  const onFinish = (values) => {
    console.log("Success:", values);
    return;
    setLoading(true);

    let data = {
      email: values.email,
      password: values.password,
    };
    Post(AUTH.signin, data)
      .then((response) => {
        console.log(response, "#####");
        setLoading(false);
        if (response?.data?.status) {
          navigate("/dashboard", { replace: true });

          dispatch(
            addUser({
              user: response.data.data.user,
              token: response.data.data.token,
            })
          );
          swal("Success", response.data.message, "success");
        } else {
          console.log("response", response);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
   <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh" }}>
        <Col xs={24} md={14}  lg={10} xl={8}>
        <Card style={{borderRadius:"30px"}}>
            <div className="authFormBox">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={22} className="formWrap">
                
                  <Typography.Title
                    className="fontFamily1"
                    style={{ fontSize: "28px", fontWeight:800, marginBottom:"5px", color: "black", textTransform:"uppercase"  }}
                  >
                    Forgot Your Password?
                  </Typography.Title>
                  <Typography.Text
                    className="fontFamily1"
                    style={{ fontSize: "16px", fontWeight:300, color: "black"  }}
                  >                   
                      Enter Your Active Email Address. You Will Receive A Verification Code There
                  </Typography.Text>
                  <br /><br />
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Something's wrong! Please make sure you have the correct Email Address",
                          // warningOnly: true,
                        },
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="AuthFormInput"
                        
                      />
                    </Form.Item>

                   
                    <br />

                    <Form.Item>
                      <Button onClick={() => navigate("/forgetpasswordcode")}
                        type="primary"
                        htmlType="submit"
                        className="loginButton mainbtn"
                      >
                        {loading ? "Loading..." : "Continue"}
                      </Button>
                    </Form.Item>
                  </Form>

                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    <>
                    Back to
                    &nbsp;
                      <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#AE802C",
                        }}
                      >
                        Login
                      </span>
                    </>
                  </Typography.Text>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default ForgetPassword;

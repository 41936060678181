import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Modal,
  Typography,
  Menu,
  Avatar,
  Image,
  Button,
  Popover,
  Drawer,
} from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { HiShoppingCart, HiBell } from "react-icons/hi";
import { BiSolidMessageRounded, BiSearch } from "react-icons/bi";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import { useSelector, useDispatch } from "react-redux";
import { UPLOADS_URL } from "../../config/constants/api";
import { removeUser } from "../../redux/slice/authSlice";

import ProfileIcon from "../../assets/avatar.png";
import { decrementCounter, setRedirected } from "../../redux/slice/timerSlice";

const items = [
  {
    label: "Home",
    key: "/home",
  },
  {
    label: "About Rituals Masonis",
    key: "/about",
  },
  {
    label: "Online Services",
    key: "/onlineservices",
  },
  {
    label: "Masonic and Religious Community",
    key: "/masonicCommunity",
  },
  {
    label: " Non-masonic Community",
    key: "/nonmasoniccomunity",
  },
  // {
  //   label: "VIP messaging",
  //   key: "/vipmessaging",
  // },
  {
    label: "Terms and conditions",
    key: "/termsandconditions",
  },
  {
    label: "Premium content",
    key: "/premiumcontent",
  },
];

function Header({ header }) {
  const user = useSelector((state) => state.user.userData);
  // const token = useSelector((state) => state.user.userToken);
  const token = true;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [current, setCurrent] = useState("mail");
  const [open, setOpen] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);

  // const counter = useSelector((state) => state.timer.counter);
  // const [resetCounter, setResetCounter] = useState(counter);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     dispatch(setRedirected());
  //     navigate("/login", { state: { redirected: true } });
  //   }, 60000); // 3 seconds

  //   const interval = setInterval(() => {
  //     dispatch(decrementCounter());
  //     setResetCounter((prev) => prev - 1);
  //     console.log(`Time remaining: ${resetCounter} seconds`);
  //   }, 1000); // Update counter every second

  //   return () => {
  //     clearTimeout(timer); // Cleanup the timer on unmount
  //     clearInterval(interval); // Cleanup the interval on unmount
  //   };
  // }, [navigate, dispatch]);

  const logout = () => {
    setLogoutModal(false);

    dispatch(removeUser());
    navigate("/login");
  };

  console.log(token, "token");

  const content = (
    <div>
      <Button className="profile_btn" onClick={() => navigate("/profile")}>
        Profile
      </Button>
      <Button className="profile_btn" onClick={() => setLogoutModal(true)}>
        logout
      </Button>
    </div>
  );
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const onClick = (e) => {
    console.log("click ", e);
    navigate(e.key);
    setCurrent(e.key);
  };
  return (
    <Row justify="center" className={header ? "navigation1" : "navigation"}>
      <Col xs={24} lg={22}>
        {token && (
          <Row>
            <div className="top">
              <HiShoppingCart onClick={() => navigate("/my-cart")} />
              <HiBell />
              <BiSolidMessageRounded />
              <BiSearch />
            </div>
          </Row>
        )}

        <Row style={{ alignItems: "center", padding: "15px 0" }}>
          <Col xs={12} sm={12} lg={2} xl={2}>
            <Image className="logo" preview={false} src={Logo} />
          </Col>

          <Col xs={0} sm={0} md={0} lg={16} xl={18} justify="center">
            <Menu
              className="mainMenu"
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>

          <Col xs={12} sm={12} lg={6} xl={4} justify={"end"}>
            <Row style={{ width: "100%", padding: "0px 50px" }}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={0}
                style={{ textAlign: "right" }}
              >
                <MenuOutlined
                  style={{ fontSize: "25px" }}
                  onClick={() => showDrawer()}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={0} sm={0} md={0} lg={24}>
                {token ? (
                  <>
                    <Popover content={content}>
                      <Avatar
                        size={50}
                        src={
                          !user.image
                            ? ProfileIcon
                            : UPLOADS_URL + "/" + user.image
                        }
                      />
                      {/* <Image preview={false} src={ProfileIcon} /> */}
                    </Popover>{" "}
                    <Button
                      className="mainbtn"
                      onClick={() => navigate("/livestreaming")}
                    >
                      Live Stream
                    </Button>
                  </>
                ) : (
                  <>
                    {" "}
                    <Button
                      className="mainbtn"
                      onClick={() => navigate("/login")}
                    >
                      Sign In
                    </Button>
                    &emsp;
                    <Button
                      className="mainbtn"
                      onClick={() => navigate("/signup")}
                    >
                      Sign Up
                    </Button>
                  </>
                )}
              </Col>
            </Row>
            {/* */}

            <Drawer
              placement={"left"}
              closable={false}
              onClose={onClose}
              open={open}
              key={"left"}
            >
              <Menu
                className="mainMenu"
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
              <div className="mobileprofile">
                <Popover content={content}>
                  <Button className="profile">
                    <Image preview={false} src={ProfileIcon} />
                  </Button>
                </Popover>{" "}
                <Button className="mainbtn">Live Stream</Button>
              </div>
            </Drawer>

            <Modal
              visible={logoutModal}
              onOk={() => logout()}
              onCancel={() => setLogoutModal(false)}
              okText="Yes"
              centered
              className="StyledModal"
              style={{
                left: 0,
                right: 0,
                margin: "auto",
                // marginLeft: "auto",
                // marginRight: "auto",
                textAlign: "center",
              }}
              cancelText="No"
              cancelButtonProps={{
                style: {
                  color: "#a77721",
                  height: "auto",
                  padding: "5px 35px",
                  borderRadius: "5px",
                  fontSize: "16px",
                  marginTop: "15px",
                  border: "2px solid #a77721",
                },
              }}
              okButtonProps={{
                style: {
                  background:
                    "linear-gradient(to right, #a77721 55%,#d5af68 93%,#d5af68 93%)",
                  color: "white",
                  marginTop: "15px",
                  height: "auto",
                  padding: "5px 35px",
                  borderRadius: "5px",
                  fontSize: "16px",
                },
              }}
            >
              <Typography.Title level={4} style={{ fontSize: "25px" }}>
                Logout
              </Typography.Title>
              <Typography.Text style={{ fontSize: 16 }}>
                Are You Sure You Want To Logout ?
              </Typography.Text>
            </Modal>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Header;

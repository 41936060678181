import React from "react";
import { Row, Col, Form, Input, InputNumber, DatePicker, Button } from "antd";
const onChange = (date, dateString) => {
  console.log(date, dateString);
};
function Payment({ next }) {
  const handleFormSubmit = (values) => {
    next();
  };
  return (
    <div className="checkout">
      <h3>Payment</h3>

      <Form
        layout="vertical"
        name="basic"
        className="loginForm"
        labelCol={{
          span: 0,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Card Holder Name"
              name="card-name"
              onFinish={handleFormSubmit}
              rules={[
                {
                  required: true,
                  message: "Card Holder Name  is Required",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Card Holder Name"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Card Number"
              name="card-number"
              rules={[
                {
                  required: true,
                  message: "Card Number is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Card Number"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>

          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="CVV"
              name="CVV-number"
              rules={[
                {
                  required: true,
                  message: "CVV Number is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter CVV Number"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Expiry Date"
              name="expiry-date"
              rules={[
                {
                  required: true,
                  message: "Expiry Date is Required",
                },
              ]}
            >
              <DatePicker
                onChange={onChange}
                picker="month"
                style={{ width: "100%" }}
                size="large"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Row
            justify="right"
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <Form.Item>
              <Button
                className="mainbtn"
                type="primary"
                htmlType="submit"
                size="large"
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Row>
      </Form>
    </div>
  );
}

export default Payment;

// import React from 'react'
// import { Row, Col, Image, Avatar, Input,  Button, Upload  } from 'antd'
// import {FcAddImage} from 'react-icons/fc';

// function PostHead() {
//     const props = {
//         action: '//jsonplaceholder.typicode.com/posts/',
//         listType: 'picture',
//         previewFile(file) {
//           console.log('Your upload file:', file);
//           // Your process logic. Here we just mock to the same file
//           return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
//             method: 'POST',
//             body: file,
//           })
//             .then((res) => res.json())
//             .then(({ thumbnail }) => thumbnail);
//         },
//       };
//   return (
//     <Row justify="center" gutter={30} className='info-area padding-y-30 greybg margin-y-40 border-radius-15'>
            
//             <Col xs={23} md={22} xl={22}>
              
//                 <Row gutter={20}>
//                     <Col xs={5} md={4} lg={2} xl={1}> <Avatar size={32} icon={<Image preview={false} src={ImageUrl("service1.png")} />} /></Col>
//                     <Col xs={19} md={20} lg={22} xl={23}>
//                     <Input placeholder="Abc" className='border-radius-15' />
                    
//                     </Col>
//                 </Row>
//                 <hr  className='margin-y-30' />
//                 <Row justify={"space-around"}>
//                 <Col xs={12}>
//                 <Upload {...props}>
//     <Button className='uploadbtn' icon={<FcAddImage size={24} />}>Image Upto (5MB)</Button>
//   </Upload>
//                     </Col>
//                     <Col xs={12} style={{textAlign:'right'}}>
//                     <Button className="mainbtn padding-x-40">Post</Button> 
//                     </Col>
//                 </Row>
//             </Col>
//             </Row>
//   )
// }

// export default PostHead

// import router from "next/router";
import react, { useState } from "react";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Input,
  Button,
  Popover,
  Layout,
  Avatar,
  Tabs,
  Table,
  Select,
  Image,
  Modal,
  Skeleton,
  message,
  Upload,
} from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { Post } from "../../config/api/post";
import swal from "sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { POST, UPLOADS_URL } from "../../config/constants/api";
import { CONTENT_TYPE } from "../../config/constants";
import { useNavigate, useParams } from "react-router-dom";
import avatar from "../../assets/avatar.png";
import { ImageUrl } from "../../config/functions";

function PostBox({isMasonic = false,getAllPosts }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);

  const createPost = () => {
    const formObject = new FormData();

    if (image) {
      formObject.append("image", image);
    }
    formObject.append("title", title);
    formObject.append("isMasonic", isMasonic);

    Post(POST.addPost, formObject, token, null, CONTENT_TYPE.FORM_DATA)
      .then((response) => {
        console.log(">>", response);

        if (response?.data?.status || response?.response?.data?.status) {
          swal("Success!", "Post Added Successfully", "success");
          getAllPosts()
          setImage();
          setImageUrl();
          setTitle();
        } else {
          swal(
            "Oops!",
            response?.data?.message || response?.response?.data?.message,
            "error"
          );
        }
      })
      .catch((e) => {
        swal(
          "Error!",
          e.response.data.message || e.response.response.data.message,
          "error"
        );
        console.log(">>>>", e);
      });
  };

  return (
    <Row
      justify="center"
     
      className="info-area padding-y-30 greybg margin-y-40 border-radius-15"
    >
      <Col xs={23} md={22} xl={22}>
        <Row gutter={30} justify="space-between">
          <Col xs={5} md={4} lg={2} xl={1}>
            {" "}
            <Avatar
              size={50}
              icon={<Image preview={false} src={user.image ? UPLOADS_URL + "/"  + user.image : ImageUrl("avatar.png")} />}
            />
          </Col>
          <Col xs={19} md={20} lg={22} xl={23} style={{paddingLeft:30}}>
            <Input
              size="medium"
              placeholder="Would you like to post something"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={{
                borderRadius: "100px",
                background: "#fff",
                fontSize: "14px",
                padding: "14px 20px",
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          {imageUrl && (
            <Image
              src={imageUrl}
              alt="Analytics Image"
              preview={false}
              width={"100%"}
            />
          )}
        </Row>

        <hr className="margin-y-30" />
        <Row justify={"space-around"}>
          <Col xs={12}>
            <Upload
              name="image"
              showUploadList={false}
              style={{ position: "relative" }}
              beforeUpload={(file) => {
                setImageUrl(URL.createObjectURL(file));
                setImage(file);
                return false;
              }}
            >
              <Button className="img-upload-btn" size="large" icon={<FileImageOutlined />}>
                Image Upto (5MB)
              </Button>
            </Upload>
          </Col>
          <Col xs={12} style={{ textAlign: "right" }}>
            <Button className="mainbtn padding-x-40" onClick={() => createPost()}>Post</Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PostBox;

import React from "react";
import { Col, Row, Image, Card, Button } from "antd";
import { Link } from "react-router-dom";
import LockedBook from "../../components/lockbook";

function LibraryAccess() {
  return (
    <>
      <div className="featuredarea">
        <Row justify="center">
          <Col xs={22} md={18} lg={18}>
            <Row justify="center">
              <Col xs={22} md={18} lg={14} className="">
                <h2>Library Access</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="eventsarea">
        <Row justify="center">
          <Col xs={22} md={18} lg={18}>
            <Row>
              <Col xs={18} md={21} lg={21}>
                <h2>Featured Books</h2>
              </Col>
              <Col xs={6} md={3} lg={3}>
                <Link to="/featuredbooks" className="whiteanchor">
                  View All
                </Link>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <LockedBook featured={true} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div className="featuredarea">
        <Row justify="center">
          <Col xs={22} md={18} lg={18}>
            <Row justify="center">
              <Col xs={22} md={18} lg={14} className="">
                <h2>Masonic Books</h2>
                <p>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.{" "}
                </p>
              </Col>

              <Row>
                <Col xs={24}>
                  <LockedBook />
                </Col>

                <Col xs={24} style={{ textAlign: "center" }}>
                  <Button className="mainbtn">View All</Button>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default LibraryAccess;

import React from "react";
// import AuthLayout from "../../components/";
import {
  Col,
  Row,
  Typography,
  List,
  Form,
  Layout,
  Input,
  Button,
  Select,
  Space,
  Checkbox,
  Card,
  Table,
  Image,
  Divider,
  InputNumber,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { AUTH } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import { FiMail, FiLock } from "react-icons/fi";
import swal from "sweetalert";

// import router from "next/router";

function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  // useEffect if user is already logged in


  const onFinish = (values) => {

    setLoading(true);

 
    Post(AUTH.signup, values)
      .then((response) => {
        setLoading(false);
        if (response?.data?.status) {
          // navigate("/loginsubscription", { state: {user:response.data} })
          navigate("/signupform/1")
          swal("Success", response.data.message, "success");
        } else {
          console.log("response", response);
          swal("Oops!", response.data.message, "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <Layout className="banner">
      <Row className="flex" style={{ minHeight: "100vh",paddingTop:150,paddingBottom:100 }}>
        <Col xs={24} md={14}  lg={10} xl={8}>
        <Card style={{borderRadius:"30px"}}>
            <div className="authFormBox">
              <Row style={{ width: "100%", justifyContent: "center" }}>
                <Col xs={20} md={22} className="formWrap">
                
                  <Typography.Title
                    className="fontFamily1"
                    style={{ fontSize: "28px", fontWeight:800, marginBottom:"5px", color: "black", textTransform:"uppercase"  }}
                  >
                     Sign Up
                  </Typography.Title>
                  <Typography.Text
                    className="fontFamily1"
                    style={{ fontSize: "16px", fontWeight:300, color: "black"  }}
                  >                   
                      Enter Your Sign Up Details
                  </Typography.Text>
                  <br /><br />
                  <br />
                  <Form
                    layout="vertical"
                    name="basic"
                    className="loginForm"
                    labelCol={{
                      span: 24,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Form.Item
                      label="Full Name"
                      name="fullName"
                      rules={[
                        {
                          required: true,
                          message: "Full name is required",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Full Name"
                        className="AuthFormInput"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Email Address"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Enter Valid Email Address",
                        },
                        {
                          required: true,
                          message: "Enter Email Address",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address"
                        className="AuthFormInput"
                      />
                    </Form.Item>

                    <Form.Item
                      label="Birthday"
                      name="birthday"
                      rules={[
                        {
                          required: true,
                          message: "Birthday is required",
                        },
                      ]}
                    >
                      <DatePicker
                        // size="large"
                        placeholder="Select Birthday"
                        className="AuthFormInput"
                      />
                    </Form.Item>


                    <Row gutter={20}>
                      <Col xs={24} md={12}>
                        <Form.Item
                          label="Phone Number"
                          name="phoneNumber"
                          rules={[
                            {
                              required: true,
                              message: "Phone number is required",
                            },
                          ]}
                        >
                            <Input
                        size="large"
                        placeholder="Enter Phone Number"
                        className="AuthFormInput"
                      />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12}>
                        <Form.Item label="Language" name="language"  rules={[
                            {
                              required: true,
                              message: "Language is required",
                            },
                          ]}>
                          <Select
                           size="large"
                           className="AuthFormSelect"
                            style={{
                              width: " 100%",
                           
                            }}
                           
                            onChange={handleChange}
                            options={[
                              {
                                value: "English",
                                label: "English",
                              },
                              {
                                value: "Spanish",
                                label: "Spanish",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={24} md={24}>
                        <Form.Item
                          label="Password*"
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Password is required",
                            },
                          ]}
                        >
                          <Input.Password
                            size="large"
                            placeholder="Enter Password"
                            className="AuthFormInput"
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} md={24}>
                        <Form.Item
                          label="Confirm Password"
                          name="passwordconfirm"
                          rules={[
                            {
                              required: true,
                              message: "Confirm password is required",
                            },
                          ]}
                        >
                          <Input.Password
                            size="large"
                            placeholder="Confirm Password"
                            className="AuthFormInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <br />

                    <Form.Item>
                      <Button 
                        type="primary"
                        htmlType="submit"
                        className="loginButton mainbtn"
                      >
                        {loading ? "Loading..." : "Continue"}
                      </Button>
                    </Form.Item>
                  </Form>
                  <Typography.Text
                    className="fontFamily1"
                    style={{
                      fontSize: "16px",
                      color: "black",
                      textAlign: "left",
                      marginTop: 0,
                      marginBottom: 30,
                    }}
                  >
                    <>
                    Back to
                    &nbsp;
                      <span
                        onClick={() => navigate("/login")}
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          color: "#AE802C",
                        }}
                      >
                        Login
                      </span>
                    </>
                  </Typography.Text>
                
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
}

export default Signup;

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Button,
  Input,
  Space,
  Form,
  message,
} from "antd";
import Logo from "../../assets/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Get } from "../../config/api";
import { RANK } from "../../config/constants/api";

function FirstDegree() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [ranking, setRanking] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const token = useSelector((state) => state.user.userToken);

  useEffect(() => {
    if (id) {
      getRanking();
    }
  }, [id]);

  const getRanking = async () => {
    setLoading(true);
    try {
      const response = await Get(`${RANK.getRank}${id}`, token);
      if (response?.status) {
        setRanking(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await Get(
        `${RANK.isEligible}${ranking?.degree}`,
        token,
        {
          PCS: values?.pcs,
        }
      );

      if (response.status === true) {
        if (response?.data.viewContent) {
          navigate("/library-content-first-rank-topic/" + id);
        } else if (response?.data.viewTest) {
          navigate("/degree-details/" + id);
        }
      } else {
        message.error(response?.response?.data?.message);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div>
        <Row justify="center" className="whitebg">
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <Card className="reviewarea text-center">
                  <h3>{ranking?.rankName}</h3>
                  <h6 className="mousepointer" onClick={onFinish}>
                    {ranking?.rank}
                  </h6>
                  <p>Lorem Ipsum is simply dummy </p>
                  <Form form={form} onFinish={onFinish}>
                    <Form.Item
                      name="pcs"
                      rules={[
                        { required: true, message: "Please input your PCS!" },
                      ]}
                    >
                      <Input placeholder="Your PCS Here" />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="mainbtn"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                  <Image width={150} src={Logo} preview={false} />
                  <p style={{ marginTop: "50px" }}>
                    Lorem <span className="goldencolor">{ranking?.rank}</span>{" "}
                    Ipsum is simply dummy{" "}
                  </p>
                  <p>Lorem Ipsum is simply dummy </p>
                  <span>
                    Ipsum is simply dummy
                    <br />
                    simply dummy
                  </span>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default FirstDegree;

import React from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import swal from "sweetalert";
import { Post } from "../../config/api";
import { CONTACT } from "../../config/constants/api";
import { useSelector } from "react-redux";

function ContactUs() {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = React.useState(false);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await Post(
        CONTACT.addFeedback,
        {
          name: values.name,
          email: values.email,
          subject: values.subject,
          message: values.message,
        },
        token,
        null
      );
      if (response?.status) {
        message.success("Feedback submitted successfully!");
        navigate("/home", { replace: true });
      }
    } catch (error) {
      console.log(error);
      message.error("Failed to submit feedback!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={22} xl={20}>
          <Row justify="center">
            <Col xs={22} md={20} xl={18}>
              <h2>Contact Us</h2>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua
              </p>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={22} md={20} xl={16}>
              <div className="shadowwhitebg padding-x-30 padding-y-40 margin-0 margin-y-40 border-radius-15">
                <div className="authFormBox">
                  <Row style={{ width: "100%", justifyContent: "center" }}>
                    <Col xs={24} md={24} className="formWrap">
                      <br />
                      <Form
                        layout="vertical"
                        name="basic"
                        className="loginForm"
                        labelCol={{
                          span: 0,
                        }}
                        wrapperCol={{
                          span: 24,
                        }}
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                      >
                        <Form.Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your name",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Name"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Email Address"
                          name="email"
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                            {
                              required: true,
                              message: "Please enter your email address",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Email Address"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Subject"
                          name="subject"
                          rules={[
                            {
                              required: true,
                              message: "Please enter a subject",
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            placeholder="Enter Subject"
                            className="AuthFormInput"
                          />
                        </Form.Item>

                        <Form.Item
                          label="Message"
                          name="message"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your message",
                            },
                          ]}
                        >
                          <Input.TextArea
                            placeholder="Enter Message Here..."
                            rows={5}
                          />
                        </Form.Item>

                        <br />

                        <Form.Item style={{ textAlign: "center" }}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="loginButton mainbtn"
                            loading={loading}
                          >
                            {loading ? "Loading..." : "Submit Now!"}
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ContactUs;

import React, { useState, useMemo } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  InputNumber,
  Select,
  Button,
} from "antd";
import countryList from "react-select-country-list";

const onChange = (value) => {
  console.log(`selected ${value}`);
};
const onSearch = (value) => {
  console.log("search:", value);
};

function PersonalInformation({ setPersonalInfo, next }) {
  const options = useMemo(() => countryList().getData(), []);
  const [shipping, setShipping] = useState(false);

  const handleFormSubmit = (values) => {
    const personalInfo = {
      firstName: values["first-name"],
      lastName: values["last-name"],
      email: values["email"],
      phone: values["phone"],
      shipping: {
        address: values["address"],
        country: values["country"],
        city: values["town-city"],
        state: values["state"],
        zip: values["postcode"],
      },
      billing: shipping
        ? {
            address: values["shipping-address"],
            country: values["shipping-country"],
            city: values["shipping-town-city"],
            state: values["shipping-state"],
            zip: values["shipping-postcode"],
          }
        : {
            address: values["address"],
            country: values["country"],
            city: values["town-city"],
            state: values["state"],
            zip: values["postcode"],
          },
      differetAddress: shipping,
    };

    console.log(personalInfo);

    next();

    setPersonalInfo(personalInfo);
  };

  return (
    <div className="checkout">
      <h3>PERSONAL INFORMATION</h3>
      <Form
        layout="vertical"
        name="basic"
        className="loginForm"
        onFinish={handleFormSubmit}
        labelCol={{
          span: 0,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="First Name"
              name="first-name"
              rules={[
                {
                  required: true,
                  message: "First Name",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Full Name"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Last Name"
              name="last-name"
              rules={[
                {
                  required: true,
                  message: "Last Name",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Last Name"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Address",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Address"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please input valid email!",
                  // warningOnly: true,
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Email Address"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Phone Number is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Phone Number"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Select Country",
                },
              ]}
            >
              <Select
                size="large"
                className="AuthFormInput"
                showSearch
                placeholder="Country"
                optionFilterProp="children"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Town/City"
              name="town-city"
              rules={[
                {
                  required: true,
                  message: "Enter Town/City",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter Town/City"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="State / County"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Enter State / County",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Enter State / County"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <Form.Item
              label="Postcode / ZIP"
              name="postcode"
              rules={[
                {
                  required: true,
                  message: "Postcode / ZIP is Required",
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Postcode / ZIP"
                className="AuthFormInput"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <Form.Item
              name="ship"
              valuePropName="checked"
              style={{
                marginBottom: 0,
                color: "black",
                textAlign: "left",
              }}
            >
              <Checkbox
                checked={shipping}
                onChange={(e) => setShipping(e.target.checked)}
                style={{ marginBottom: 0, color: "#999999" }}
              >
                {" "}
                <p
                  className="fontFamily1"
                  style={{
                    margin: 0,
                    color: "#999999",
                    fontSize: "12px",
                  }}
                >
                  Ship To A Different Location
                </p>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        {shipping && (
          <Row gutter={20}>
            <Col xs={24} md={24} lg={24}>
              <h3>Shipping Details</h3>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="First Name"
                name="shipping-first-name"
                rules={[
                  {
                    required: true,
                    message: "First Name",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Full Name"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Last Name"
                name="shipping-last-name"
                rules={[
                  {
                    required: true,
                    message: "Last Name",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Last Name"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} lg={24}>
              <Form.Item
                label="Address"
                name="shipping-address"
                rules={[
                  {
                    required: true,
                    message: "Address",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Address"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Email Address"
                name="shipping-email"
                rules={[
                  {
                    type: "email",
                    message: "Please input valid email!",
                    // warningOnly: true,
                  },
                  {
                    required: true,
                    message: "Please input your email!",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Email Address"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Phone Number"
                name="shipping-phone"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Enter Phone Number"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Country"
                name="shipping-country"
                rules={[
                  {
                    required: true,
                    message: "Select Country",
                  },
                ]}
              >
                <Select
                  size="large"
                  className="AuthFormInput"
                  showSearch
                  placeholder="Country"
                  optionFilterProp="children"
                  onChange={onChange}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Town/City"
                name="shipping-town-city"
                rules={[
                  {
                    required: true,
                    message: "Enter Town/City",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter Town/City"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="State / County"
                name="shipping-state"
                rules={[
                  {
                    required: true,
                    message: "Enter State / County",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Enter State / County"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={12}>
              <Form.Item
                label="Postcode / ZIP"
                name="shipping-postcode"
                rules={[
                  {
                    required: true,
                    message: "Postcode / ZIP is Required",
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  size="large"
                  placeholder="Enter Postcode / ZIP"
                  className="AuthFormInput"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item>
          <Button
            className="mainbtn"
            type="primary"
            htmlType="submit"
            size="large"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default PersonalInformation;

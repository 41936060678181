import React, { useState, useEffect } from "react";
import { Row, Col, Image, Avatar, message } from "antd";
import { SERVICE, EVENT, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import AliceCarousel from "react-alice-carousel";
import Event1 from "../../assets/event1.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/event3.png";
import Event4 from "../../assets/event4.png";
import Event5 from "../../assets/event5.png";
import Event6 from "../../assets/event6.png";


const responsive = {
  0: { items: 1 },
  568: { items: 3 },
  1024: { items: 6 },
};
const items = [
  <div className="item" data-value="1">
    <Image preview={false} src={Event1} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="2">
    <Image preview={false} src={Event2} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="3">
    <Image preview={false} src={Event3} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="4">
    <Image preview={false} src={Event4} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="5">
    <Image preview={false} src={Event5} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="1">
    <Image preview={false} src={Event1} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="2">
    <Image preview={false} src={Event2} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="3">
    <Image preview={false} src={Event3} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="4">
    <Image preview={false} src={Event4} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
  <div className="item" data-value="5">
    <Image preview={false} src={Event5} />
    <h3>David Guetta</h3>
    <p>Songrk live, nyc</p>
    <span>11 April, 2023</span>
  </div>,
];

function UpcomingEvenets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  console.log(events, "UUU");

  const handlePageChange = (e) => {
    setPaginationConfig({
      ...paginationConfig,
      pageNumber: Number(e.selected) + 1,
    });

    getEvents(Number(e.selected) + 1);
  };

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async (pageNumber, pageSize, search, reset = false) => {
    setLoading(true);
    try {
      const response = await Get(EVENT.getAllEvents, token, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: pageSize
          ? pageSize.toString()
          : paginationConfig.limit.toString(),
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setEvents(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
      } else {
        message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="eventsarea">
      <Row justify="center">
        <Col xs={22} md={20}>
          <h2>Upcoming Events Masonic and community</h2>
        </Col>
        <Col xs={24}>
          <AliceCarousel
            mouseTracking
            responsive={responsive}
            disableButtonsControls={true}
            disableDotsControls
            controlsStrategy="alternate"
            paddingLeft={50}
            paddingRight={50}
            infinite
            autoPlay
            autoPlayInterval={800}
          >
            {events.length > 0 && events.map(item => {return( <div style={{cursor:"pointer"}} onClick={()=> navigate("/upcomingeventdetails/" + item._id)} className="item" data-value="4">
    <Image preview={false} src={UPLOADS_URL + "/" + item.image} />
    <h3>{item.title}</h3>
    <p>{item.location}</p>
    <span> {moment(item.createdAt).format('DD MMMM, YYYY')}</span>
  </div>)})}
          </AliceCarousel>
        </Col>
      </Row>
    </div>
  );
}

export default UpcomingEvenets;

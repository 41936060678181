import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Image, Button, Modal, Radio } from "antd";
import { BiSolidLock } from "react-icons/bi";
import swal from "sweetalert";
import { ImageUrl } from "../../config/functions";

function LockedBook({ featured = false }) {
  const [lockBooks, setlockBooks] = useState([
    {
      key: "1",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: true,
      locked: true,
    },
    {
      key: "2",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: true,
      locked: false,
    },
    {
      key: "3",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: true,
      locked: true,
    },
    {
      key: "4",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: true,
      locked: true,
    },
    {
      key: "5",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: false,
      locked: true,
    },
    {
      key: "6",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: false,
      locked: true,
    },
    {
      key: "7",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: false,
      locked: true,
    },
    {
      key: "8",
      image: ImageUrl("featuredbooks1.png"),
      author: "Mark Carson",
      title: "Sixty Six Law",
      pirce: "$25.00",
      bookFeatured: false,
      locked: true,
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(1);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const handleSubmit = () => {
    setIsModalOpen(false);
    swal(
      "System Message!",
      "Your Answers Against The Questions Have Been Submitted!. You'll Be Notified Whether You're Eligible To Purchase This Content Or Not.","Okay"
    );
    
  };
  const handleSubmit2 = () => {
    setIsModalOpen(false);
    swal(
      "System Message!",
      "You Are Not Eligible To Purchase The Book. Please Try Again!","error"
    );
    
  };
  return (
    <>
      <Row gutter={20}>
        {lockBooks.length > 0 &&
          lockBooks.map((item, index) => {
            if (featured) {
              if (item.bookFeatured) {
                return (
                  <Col xs={12} md={12} lg={6} xl={6}>
                    <div
                      className="lockedbook"
                      onClick={item.locked ? showModal : showModal2}
                    >
                      {item.locked && (
                        <div className="locked">
                          <BiSolidLock />
                        </div>
                      )}
                      <Image preview={false} src={item.image} />
                      <span>{item.author}</span>
                      <h3>{item.title}</h3>
                      <h3>{item.pirce}</h3>
                      <Button className="mainbtn">Get Started</Button>
                    </div>
                  </Col>
                );
              }
            } else {
              return (
                <Col xs={12} md={12} lg={6} xl={6}>
                  <div
                    className="lockedbook"
                    onClick={item.locked ? showModal : showModal2}
                  >
                    {item.locked && (
                      <div className="locked">
                        <BiSolidLock />
                      </div>
                    )}
                    <Image preview={false} src={item.image} />
                    <span>{item.author}</span>
                    <h3>{item.title}</h3>
                    <h3>{item.pirce}</h3>
                    <Button className="mainbtn">Get Started</Button>
                  </div>
                </Col>
              );
            }
          })}
      </Row>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Please Answer to the following questions</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>01: This is a dummy question number one? </p>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Option 01</Radio>
                <Radio value={2}>Option 02</Radio>
                <Radio value={3}>Option 03</Radio>
                <Radio value={4}>Option 04</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>01: This is a dummy question number one? </p>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Option 01</Radio>
                <Radio value={2}>Option 02</Radio>
                <Radio value={3}>Option 03</Radio>
                <Radio value={4}>Option 04</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>01: This is a dummy question number one? </p>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>Option 01</Radio>
                <Radio value={2}>Option 02</Radio>
                <Radio value={3}>Option 03</Radio>
                <Radio value={4}>Option 04</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button
                className="mainbtn padding-x-50"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={false}
      >
        <Row justify={"center"}>
          <Col xs={22} md={22}>
            <h4>Congrats!</h4>
          </Col>

          <Col xs={22} md={22}>
            <div className="modalinner">
              <p>
                You Are Now Eligible To Purchase The Book. Please Choose Book
                Type Below:
              </p>
              <Radio.Group onChange={onChange} value={value}>
                <Radio value={1}>I'm good with E-book</Radio>
                <Radio value={2}>I want a hardcopy</Radio>
              </Radio.Group>
            </div>
          </Col>

          <Col xs={22} md={22} style={{ textAlign: "center" }}>
            <div className="modalinner">
              <Button className="mainbtn padding-x-40">Checkout</Button>
              <Button className="mainbtn padding-x-50 margin-x-10">
                Later
              </Button>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default LockedBook;


import React, {useState,useEffect} from 'react'
import { Row, Col, Image, Button,message } from 'antd'
import { BOOK, UPLOADS_URL } from "../../config/constants/api";
import { Get } from "../../config/api/get";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import AliceCarousel from 'react-alice-carousel';
import FeatureBook1 from '../../assets/featuredbooks1.png'
import FeatureBook2 from '../../assets/featuredbooks2.png'
import FeatureBook3 from '../../assets/featuredbooks3.png'


const responsive = {
    0: { items: 1 },
    568: { items: 3},
    1024: { items: 3 },
  };
  const items = [
    <div className="item" data-value="1">
        <Image preview={false} src={FeatureBook1} />
        <h3>ashley & jaquavis</h3>
        <span>read more </span>
        </div>,
    <div className="item" data-value="2">
    <Image preview={false} src={FeatureBook2} />
        <h3>Sixty six lawa</h3>
        <span>read more </span> 
    </div>,
    <div className="item" data-value="3">
        <Image preview={false} src={FeatureBook3}  />
        <h3>facts & fictions</h3>
        <span>read more </span>
        </div>,
    <div className="item" data-value="4">
        <Image preview={false} src={FeatureBook1}  />
        <h3>ashley & jaquavis</h3>
        <span>read more </span>
    </div>,
    <div className="item" data-value="5">
        <Image preview={false} src={FeatureBook2}  />
        <h3>Sixty six lawa</h3>
        <span>read more </span> 
        </div>,
  ];


function FeaturedBooks() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.userData);
    const token = useSelector((state) => state.user.userToken);
    const [books, setBooks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginationConfig, setPaginationConfig] = useState({
      pageNumber: 1,
      limit: 10,
      totalDocs: 0,
      totalPages: 0,
    });
  
    
    const handlePageChange = (e) => {
      setPaginationConfig({
        ...paginationConfig,
        pageNumber: Number(e.selected) + 1,
      });
  
      getBooks(Number(e.selected) + 1);
    };
  
        useEffect(() => {
          getBooks();
        }, []);
  
  
        const getBooks = async (pageNumber, pageSize) => {
          setLoading(true);
          try {
            const response = await Get(BOOK.getAllBooks, token, {
              page: pageNumber
                ? pageNumber.toString()
                : paginationConfig.pageNumber.toString(),
              limit: pageSize
                ? pageSize.toString()
                : paginationConfig.limit.toString(),
            });
            setLoading(false);
            console.log("response", response);
            if (response?.status) {
              setBooks(response?.data?.docs);
              setPaginationConfig({
                pageNumber: response?.data?.page,
                limit: response?.data?.limit,
                totalDocs: response?.data?.totalDocs,
                totalPages: response?.data?.totalPages,
              });
            } else {
              message.error("Something went wrong!");
              console.log("error====>", response);
            }
          } catch (error) {
            console.log(error.message);
            setLoading(false);
          }
        };
  
  return (
    <div className='featuredarea'>
        <Row justify='center'>
            <Col xs={22} md={18} lg={18}>
                <Row  justify='center'>
                <Col xs={22} md={18} lg={14} className=''>
                    <h2>Featured Books and Masonic Rituals</h2>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. </p>
                </Col>
                <Col xs={24}>
                <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls
        controlsStrategy="alternate"
        infinite
        autoPlay
        autoPlayInterval={800}
    >
        {books.length > 0 && books.map(item => {
            return( <div className="item" data-value="4">
            <Image preview={false} src={UPLOADS_URL + "/" + item.coverImage}  />
            <h3>{item.title}</h3>
            <span>read more </span>
        </div>);
        })}
    </AliceCarousel>
                </Col>
                <Col><Button className="mainbtn"  onClick={()=>{navigate("/masonicbooks")}}>View All</Button></Col>
                </Row>
            </Col>
        </Row>
        <Row>            
        </Row>
    </div>
  )
}

export default FeaturedBooks
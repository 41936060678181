import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionAnswers: [],
};

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    addAnswer: (state, { payload }) => {
      console.log("payload", payload);
      const QA = {
        step: payload.step,
        question: payload.question,
        answer: payload.answer,
      };

      state.questionAnswers.push(QA); // Use push instead of append
    },
  },
  extraReducers: {},
});

// Action creators are generated for each case reducer function
export const { addAnswer } = questionSlice.actions;

export default questionSlice.reducer;

import React, { useEffect, useState } from "react";
import { Image, Row, Col } from "antd";
import { UPLOADS_URL } from "../../config/constants/api";

const ImageGrid = ({ smallImages }) => {
  const smallImageHeight = 100; // Adjust the height of small images here (in pixels)
  const spacing = 16; // Adjust the spacing between small images here (in pixels)
  const largeImageHeight = smallImages?.length * (smallImageHeight + spacing);
  const [selectedImage, setSelectedImage] = useState(
    smallImages ? smallImages[0] : ""
  );

  useEffect(() => {
    if (smallImages) setSelectedImage(smallImages[0]);
  }, [smallImages]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <Row gutter={8} className="h-full w-full">
      <Col span={6}>
        <Row gutter={[0, 25]}>
          {smallImages?.map((image, index) => (
            <Col span={24} key={index}>
              <Image
                preview={false}
                className="border-radius-15"
                src={UPLOADS_URL + "/" + image}
                style={{
                  height: smallImageHeight,
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => handleImageClick(image)}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <Col span={18} className="h-full w-full">
        <Image
          preview={false}
          className="border-radius-15 h-full w-full"
          src={UPLOADS_URL + "/" + selectedImage}
          style={{
            // height: largeImageHeight,
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
      </Col>
    </Row>
  );
};

export default ImageGrid;

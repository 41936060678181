import React, { useState } from "react";
import { Row, Col, Image, Avatar, Input, Button } from "antd";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegCommentDots } from "react-icons/fa";
import { ImShare2 } from "react-icons/im";
import moment from "moment";
import { UPLOADS_URL, UPLOADS_URL2 } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { ImageUrl } from "../../config/functions";
import parse from "html-react-parser";

function ArticlePostComponent({ post }) {
  const token = useSelector((state) => state.user.userToken);
  console.log(token);
  const [selectedIndex, setSelectedIndex] = useState();
  const [comment, setComment] = useState("");

  return (
    <div>
      {post && (
        <Row
          justify="center"
          className="info-area padding-y-40 greybg margin-y-40 border-radius-15"
        >
          <Col xs={23} md={22} xl={22}>
            <Row gutter={20} className="margin-y-15">
              <Col xs={5} md={4} xl={1}>
                <Avatar
                  size={48}
                  icon={
                    post?.author.image ? (
                      <Image
                        src={UPLOADS_URL + "/" + post?.author.image}
                        alt="Analytics Image"
                        preview={false}
                        className="abc"
                        width={"100%"}
                      />
                    ) : (
                      <Image preview={false} src={ImageUrl("avatar.png")} />
                    )
                  }
                />
              </Col>
              <Col xs={19} md={20} xl={23}>
                <h5 className="margin-0">
                  {post?.author.isAdmin ? "Admin" : post?.author.fullName}
                </h5>
                <p>Posted {moment(post.createdAt).fromNow()} </p>
              </Col>
            </Row>
          </Col>
          <Col xs={23} md={22} xl={22}>
            <p> {parse(post?.content)}</p>

            <div className="postimage">
              {post.image && (
                <Col xs={24} md={24}>
                  <Image
                    src={UPLOADS_URL + "/" + post.image}
                    alt="Analytics Image"
                    preview={false}
                    className="abc"
                    width={"100%"}
                  />
                </Col>
              )}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default ArticlePostComponent;

import React, { useState, useEffect } from "react";
import { Row, Skeleton, Col, List, Typography, Button } from "antd";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { PLANS } from "../../config/constants/api";
import { Get } from "../../config/api/get";

import { useSelector, useDispatch } from "react-redux";

function Packages({ fullWidth = false, title = true }) {
  const user = useSelector((state) => state.user.userData);
  const token = useSelector((state) => state.user.userToken);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });

  console.log("plans", plans);

  const data = [
    {
      key: "1",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "2",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "3",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "4",
      icon: false,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "5",
      icon: false,
      title: "Racing car sprays burning fuel into crowd.",
    },
  ];
  const data2 = [
    {
      key: "1",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "2",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "3",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "4",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
    {
      key: "5",
      icon: true,
      title: "Racing car sprays burning fuel into crowd.",
    },
  ];

  useEffect(() => {
    getAllPlans();
  }, []);

  const getAllPlans = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await Get(PLANS.getAllPlans, null, {
        page: pageNumber
          ? pageNumber.toString()
          : paginationConfig.pageNumber.toString(),
        limit: "10",
      });
      setLoading(false);
      console.log("response", response);
      if (response?.status) {
        setPlans(response?.data?.docs);
      } else {
        // message.error("Something went wrong!");
        console.log("error====>", response);
      }
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Row justify="center" className="whitebg" style={{paddingTop:150}}>
  
        {title && (
          <Col xs={22} md={20} xl={18}>
            <Row justify="center">
              <Col xs={22} md={20} xl={14}>
                <h2>
                  <Link to={"/login"}>
                    <AiOutlineArrowLeft />
                  </Link>
                  Subscription Plans
                </h2>
                <p>Premium Membership</p>
              </Col>
            </Row>
          </Col>
        )}
        <Col xs={24}>
          <Row justify="center">
            <Col xs={22}>
              <Row justify={"center"} gutter={40}>
                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Skeleton active />
                    <br />
                  </div>
                )}

                {!loading &&
                  plans.length > 0 &&
                  plans.map((item,index) => {
                    return (
                      <Col xs={22} md={9} lg={fullWidth ? 10 : 6}>
                        <div className="pricebox">
                          <div className={`silverbox ${(index%2 == 0) && "blackbox"}`}>
                          <div>
                                  <Typography.Title
                                    className="fontFamily1"
                                    style={{  
                                      fontSize: "30px",
                                      color: "white",
                                      fontWeight: 800,
                                      textTransform: "uppercase",
                                      marginTop: 20,
                                      marginBottom: 5,
                                    }}
                                  >
                                    {item.title}
                                  </Typography.Title>
                                  <Typography.Text
                                    className="fontFamily1"
                                    style={{
                                      fontSize: "18px",
                                      color: "white",
                                      marginTop: 0,
                                      marginBottom: 20,
                                    }}
                                  >
                                    {item.description}
                                  </Typography.Text>
                                </div>
                                <br/>
                                
                            <List
                              dataSource={item.features}
                              style={{maxHeight:"320px",overflow:'auto'}}
                              renderItem={(item) => (
                                <List.Item>
                                  <Typography.Text>
                                    <FaCheck
                                      size={18}
                                      style={{ color: "#59E500" }}
                                    />
                                  </Typography.Text>{" "}
                                  {item}
                                </List.Item>
                              )}
                            />
                            <div className="ribbon ribbon-top-left">
                              <span>{item.tag}</span>
                            </div>
                            <Button
                             className="fontFamily1"
                  type="primary"
                  size="large"
                  
                  style={{backgroundColor:"white",color:'black'}}
                  >
                Select Plan
                </Button>

                                
                          </div>
                        </div>
                      </Col>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Packages;

import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { CONTENT } from "../../config/constants/api";
import { Get } from "../../config/api";
import parse from "html-react-parser";

function TermsCondition() {
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState({});
  const getContent = async () => {
    setLoading(true);
    try {
      const response = await Get(CONTENT.getTermsContent);
      if (response?.status) {
        setContent(response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getContent();
  }, []);
  return (
    <>
      <Row justify="center" className="whitebg">
        <Col xs={22} md={20} xl={18}>
          <Row justify="center">
            <Col xs={22} md={20} xl={14}>
              <h2>
                {content?.heading ? content?.heading : "terms & conditions"}
              </h2>
              <p>
                {content?.subheading
                  ? content?.subheading
                  : "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua"}
              </p>
            </Col>
          </Row>
          <Row justify="center" gutter={50} className="padding-y-40 tnc">
            <Col xs={22} md={22} lg={22}>
              {content?.description ? (
                parse(content?.description)
              ) : (
                <>
                  <p>
                    Lorem Ipsum Is Simply Dummy Text Of The Printing And
                    Typesetting Industry. Lorem Ipsum Has Been The Industry's
                    Standard Dummy Text Ever Since The 1500S, When An Unknown
                    Printer Took A Galley Of Type And Scrambled It To Make A
                    Type Specimen Book. It Has Survived Not Only Five Centuries,
                    But Also The Leap Into Electronic Typesetting, Remaining
                    Essentially Unchanged.
                  </p>
                  <p>
                    Lorem Ipsum Is Simply Dummy Text Of The Printing And
                    Typesetting Industry. Lorem Ipsum Has Been The Industry's
                    Standard Dummy Text Ever Since The 1500S, When An Unknown
                    Printer Took A Galley Of Type And Scrambled It To Make A
                    Type Specimen Book. It Has Survived Not Only Five Centuries,
                    But Also The Leap Into Electronic Typesetting, Remaining
                    Essentially Unchanged. Lorem Ipsum Is Simply Dummy Text Of
                    The Printing And Typesetting Industry. Lorem Ipsum Has Been
                    The Industry's Standard Dummy Text Ever Since The 1500S,
                    When An Unknown Printer Took A Galley Of Type And Scrambled
                    It To Make A Type Specimen Book. It Has Survived Not Only
                    Five Centuries, But Also The Leap Into Electronic
                    Typesetting, Remaining Essentially Unchanged. Lorem Ipsum Is
                    Simply Dummy Text Of The Printing And Typesetting Industry.
                    Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
                    Since The 1500S, When An Unknown Printer Took A Galley Of
                    Type And Scrambled It To Make A Type Specimen Book. It Has
                    Survived Not Only Five Centuries, But Also The Leap Into
                    Electronic Typesetting, Remaining Essentially Unchanged.
                  </p>
                  <p>
                    Lorem Ipsum Is Simply Dummy Text Of The Printing And
                    Typesetting Industry. Lorem Ipsum Has Been The Industry's
                    Standard Dummy Text Ever Since The 1500S, When An Unknown
                    Printer Took A Galley Of Type And Scrambled It To Make A
                    Type Specimen Book. It Has Survived Not Only Five Centuries,
                    But Also The Leap Into Electronic Typesetting, Remaining
                    Essentially Unchanged. Lorem Ipsum Is Simply Dummy Text Of
                    The Printing And Typesetting Industry. Lorem Ipsum Has Been
                    The Industry's Standard Dummy Text Ever Since The 1500S,
                    When An Unknown Printer Took A Galley Of Type And Scrambled
                    It To Make A Type Specimen Book. It Has Survived Not Only
                    Five Centuries, But Also The Leap Into Electronic
                    Typesetting, Remaining Essentially Unchanged. Lorem Ipsum Is
                    Simply Dummy Text Of The Printing And Typesetting Industry.
                    Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
                    Since The 1500S, When An Unknown Printer Took A Galley Of
                    Type And Scrambled It To Make A Type Specimen Book. It Has
                    Survived Not Only Five Centuries, But Also The Leap Into
                    Electronic Typesetting, Remaining Essentially Unchanged.
                    Lorem Ipsum Is Simply Dummy Text Of The Printing And
                    Typesetting Industry. Lorem Ipsum Has Been The Industry's
                    Standard Dummy Text Ever Since The 1500S, When An Unknown
                    Printer Took A Galley Of Type And Scrambled It To Make A
                    Type Specimen Book. It Has Survived Not Only Five Centuries,
                    But Also The Leap Into Electronic Typesetting, Remaining
                    Essentially Unchanged. Lorem Ipsum Is Simply Dummy Text Of
                    The Printing And Typesetting Industry. Lorem Ipsum Has Been
                    The Industry's Standard Dummy Text Ever Since The 1500S,
                    When An Unknown Printer Took A Galley Of Type And Scrambled
                    It To Make A Type Specimen Book. It Has Survived Not Only
                    Five Centuries, But Also The Leap Into Electronic
                    Typesetting, Remaining Essentially Unchanged. Lorem Ipsum Is
                    Simply Dummy Text Of The Printing And Typesetting Industry.
                    Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
                    Since The 1500S, When An Unknown Printer Took A Galley Of
                    Type And Scrambled It To Make A Type Specimen Book. It Has
                    Survived Not Only Five Centuries, But Also The Leap Into
                    Electronic Typesetting, Remaining Essentially Unchanged.
                    Lorem Ipsum Is Simply Dummy Text Of The Printing And
                    Typesetting Industry. Lorem Ipsum Has Been The Industry's
                    Standard Dummy Text Ever Since The 1500S, When An Unknown
                    Printer Took A Galley Of Type And Scrambled It To Make A
                    Type Specimen Book. It Has Survived Not Only Five Centuries,
                    But Also The Leap Into Electronic Typesetting, Remaining
                    Essentially Unchanged. Lorem Ipsum Is Simply Dummy Text Of
                    The Printing And Typesetting Industry. Lorem Ipsum Has Been
                    The Industry's Standard Dummy Text Ever Since The 1500S,
                    When An Unknown Printer Took A Galley Of Type And Scrambled
                    It To Make A Type Specimen Book. It Has Survived Not Only
                    Five Centuries, But Also The Leap Into Electronic
                    Typesetting, Remaining Essentially Unchanged. Lorem Ipsum Is
                    Simply Dummy Text Of The Printing And Typesetting Industry.
                    Lorem Ipsum Has Been The Industry's Standard Dummy Text Ever
                    Since The 1500S, When An Unknown Printer Took A Galley Of
                    Type And Scrambled It To Make A Type Specimen Book. It Has
                    Survived Not Only Five Centuries, But Also The Leap Into
                    Electronic Typesetting, Remaining Essentially Unchanged.
                  </p>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default TermsCondition;
